.right-cont {
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    .navigations {
        display:flex;
        justify-content: flex-end;
        height: 30px;
        align-items: center;
        text-align: center;
        gap: 15px;
        margin-bottom: 10px;
        
        
        .nav {


        p {
            background: #7e685a;
            border-radius: 5px;
           
            color: white;
            height: 100%;
            width: fit-content;
            // padding-top: 10px;
            padding: 10px;
            font-size: 13px;
        }
    }

    }

    .live {
        background: #7e685aB3;
        height: 35px;
        align-items: center;

        p {
            margin: 0;
            padding-left: 20px;
            padding-top: 10px;
            color: white;

        }
    }

    .score_card {
        background: #7e685aB3;
        height: 35px;

        p {
            margin: 0;
            padding-left: 20px;
            padding-top: 10px;
            color: white;

        }

    }

    .matched-cont {
        // height: 160px;
        background: #7e685aB3;
        display: flex;
        flex-direction: column;
      
        .matched-div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;

            .combo-div {
               align-items: center;
            }

        p {
            font-size: 10px;
            margin: 0;
            padding: 20px;
            color: white;
            // border: 2px solid blue;
           
    
            span {
                font-size: 10px;
                border-bottom: 2px solid #7e685aB3;
                height: 50px;
                letter-spacing: -1px;
                font-weight: 600;
                font-size: 15px;
                padding: 8px 5px;
                gap: -2px;
                cursor: pointer;
                border: 1px solid green;
                border: none;
                border-bottom: 2px solid #7e685aB3;

                &:hover {
                //    border: 1px solid white;
                }


            }

            .matched {
                
                height: 100px;
                margin-right: 5px;
                cursor: pointer;
              
                &:hover {
                    // border: 1px solid white;
                }
                
            }
            .active-class {
                background: #7e685aB3;
                height: 100px;
                margin-right: 5px;
                border: none;
                // border: 1px solid #7e685aB3;
                border: none
            }
            .unmatched {
                height: 100%;
                margin-right: 5px;
                text-decoration: none;
                cursor: pointer;
               
                &:hover {
              
                }

            }

        }
        button {
            margin-right: 20px;
            height: 60%;
            background: #7e685aB3;
            color: white;
            border-radius: 5px;
            border: none;
            padding: 10px;
        }
    }

    .user_name-cont {
        
     background: white;
     border: 1px solid rgb(209, 207, 207);
     border-top: hidden;
     display: flex;
     justify-content: space-between;
     width: 100%;
     height: 40px;
     align-items: center;

        p {
            margin: 0;
            font-weight: 700;
            padding: 5px 0 5px 10px;
        }

        .user_name-left {
            width: 55%;

        }

        .user_name-right {
            flex: 1;
            display: flex;
            justify-content: space-between;
            padding-right: 10px;
            font-size: 15px;
            font-weight: 600;
            
            
        }


    }

    }

}

.overlays {
    height: 100px;
}