@import "../../../styles.module.scss";

.edit_profile-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 10px;

  .edit_profile-field {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    gap: 20px;
    width: 95%;

    label {
      width: 30%;
    }

    .edit_profile-input {
      flex: 1;
      // width: 700px;
    }

    .edit-profile-eye {
      position: absolute;
      right: 5%;
      top: 71%;
    }
  }

  .edit_profile-favorite {
    margin-bottom: 10px;
    display: flex;
    width: 100%;

    label {
      width: 30%;
    }
  }

  .edit_profile-button {
    width: 95%;
    justify-content: end;
    display: flex;

    button {
      color: white;
      width: 80px;

      padding: 0.375rem 0.75rem;
      font-size: 14px;
      align-items: center;
      margin-bottom: 10px;
      border: none;
      border-radius: 5px;
      background: $theme_color2;
    }
  }
}
