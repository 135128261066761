.ledger_sec{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: 800px) {
        justify-content: unset;
    }
    .sub_ledger{
        width: 18%;
        @media screen and (max-width: 800px) {
            width: 100%;
            margin: 12px;
        }
        .ant-picker{
            width: 100%;
        }
    }
    .balnce_show{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 50%;
        @media screen and (max-width: 800px) {
            width: 100%;
        }
    }
    .ledger_balance{
        font-size: 18px;
        padding-top: 17px;
    }
}