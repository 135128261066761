@import "../../../styles.module.scss";

.table_cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 720px) {
    flex-direction: column;
  }
  .table_top {
    width: 100%;
  }

  .table_bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    @media (max-width: 720px) {
      flex-direction: column;
    }
  }

  .table-left {
    display: flex;
    flex-direction: column;
    width: 65%;
    @media (max-width: 720px) {
      width: 100%;
    }
  }

  .table-right {
    flex: 1;
  }
}
