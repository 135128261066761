.aaa-cont {
    width: 98vw;
    height: 100vh;

    .aaa-iframe {
        width: 100%;
        height: 100%;

    }

}