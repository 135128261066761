.password_buttons {
    float: right;
    button {
        background: black;
       margin-right: 10px;
       padding: 4px 15px;
       height: 35px;
       font-size: 1rem;
    //    width: 120px;
    //    height: 40px;
        border: none;
        color: white;
        border-radius: 5px;

    }
}