.current_bet-form {
  // margin-left: 15px;
  width: calc(100% - 10px);
  padding-inline: 10px;

  .current_bet-bottom {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    // border: 2px solid green;
    margin-bottom: 20px;
    justify-content: space-between;
    .current_bet-select {
      // padding: 7px;
      margin-top: 5px;
      width: 40%;
      @media (max-width: 720px) {
        width: 48%;
      }
      .sub_select {
        // width: 40%;
        padding: 8px;
        width: 200px;
        @media (max-width: 720px) {
          width: 100%;
        }
      }
    }

    .radio {
      display: flex;
      align-items: center;
      width: 40%;
      gap: 10px;
      justify-content: end;
      @media (max-width: 720px) {
        width: 48%;
      }
      input {
        width: 20px;
      }
      label {
        position: relative;
        top: 3px;
        font-size: 15px;
      }
    }

    .load-button {
      // margin-left: 30px;
      font-size: 16px;
      margin-right: 30px;
      padding-left: 10px;
      padding-top: 7px;
      padding-bottom: 7px;
      padding-right: 10px;
      // padding: 10px;
      background: #7e685a;
      border: none;
      color: white;
      border-radius: 5px;
      width: 10%;
    }
  }
}
