body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto Condensed", sans-serif;
}

* {
  font-family: "Roboto Condensed", sans-serif;
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.ant-modal,
.ant-modal-content {
  /* height: 90%; */
  /* width: 100%; */
  padding: 0px !important;
  border-radius: 10px;
  /* min-width: 1000px;
  width: 100%; */
  /* min-width: 400px; */
}

.ant-modal-body {
  /* background-color: black; */
}
.ant-modal .ant-modal-close:hover {
  background-color: transparent !important;
}
.ant-table-cell {
  white-space: nowrap;
}
.search label {
  margin-right: 10px;
}

.ant-menu {
  background-color: transparent !important;
}
