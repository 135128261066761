.row-cont {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    gap: 20px;
   font-family: sans-serif;

 .left-row {
    width: 64%;
    height: 100%;
  

 }  
  .right-row {
    flex: 1;
   margin-top: 12px;
  }
}