@import "../../../styles.module.scss";

.right-cont {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .live {
    background: $theme_color2;
    height: 35px;
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    padding-right: 15px;

    p {
      margin: 0;
      padding-left: 20px;
      padding-top: 10px;
      color: white;
    }
  }

  .score_card {
    background: $theme_color2;
    height: 35px;
    cursor: pointer;

    p {
      margin: 0;
      padding-left: 20px;
      padding-top: 10px;
      color: white;
    }
  }

  .matched-cont {
    // height: 160px;
    background: $theme_color2;
    display: flex;
    flex-direction: column;

    .matched-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;

      .combo-div {
        align-items: center;
      }

      p {
        font-size: 10px;
        margin: 0;
        padding: 20px;
        color: white;
        // border: 2px solid blue;

        span {
          font-size: 10px;
          border-bottom: 2px solid $theme_color2;
          height: 50px;
          letter-spacing: -1px;
          font-weight: 600;
          font-size: 15px;
          padding: 8px 5px;
          gap: -2px;
          cursor: pointer;
          border: 1px solid green;
          border: none;
          border-bottom: 2px solid $theme_color2;

          &:hover {
            //    border: 1px solid white;
          }
        }

        .matched {
          height: 100px;
          margin-right: 5px;
          cursor: pointer;

          &:hover {
            // border: 1px solid white;
          }
        }
        .active-class {
          background: $theme_color;
          height: 100px;
          margin-right: 5px;
          border: none;
          // border: 1px solid #7e685aB3;
          border: none;
        }
        .unmatched {
          height: 100%;
          margin-right: 5px;
          text-decoration: none;
          cursor: pointer;

          &:hover {
          }
        }
      }
      button {
        margin-right: 20px;
        height: 60%;
        background: $theme_color2;
        color: white;
        border-radius: 5px;
        border: none;
        padding: 10px;
      }
    }

    // .ant-table-thead {
    //     padding: 0px;
    //     padding-top: 10px;

    //     tr {
    //         padding: 0px;

    //         .ant-table-cell {
    //             border-radius: 0px !important;

    //         }
    //     }
    // }
    .ant-table-cell {
      &:first-child {
        border-start-start-radius: 0px !important;
      }
      &:last-child {
        border-start-end-radius: 0px !important;
      }
    }
    // .ant-table-cell{
    //     &:first-child{
    //         border-start-start-radius: 0px !important;
    //     }
    // }
    .bet-list-table {
    }
    .user_name-cont {
      background: white;
      border: 1px solid rgb(209, 207, 207);
      border-top: hidden;
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 40px;
      align-items: center;

      p {
        margin: 0;
        font-weight: 700;
        padding: 5px 0 5px 10px;
      }

      .user_name-left {
        width: 55%;
      }

      .user_name-right {
        flex: 1;
        display: flex;
        justify-content: space-between;
        padding-right: 10px;
        font-size: 15px;
        font-weight: 600;
      }
    }
  }
}

.overlays {
  height: 100px;
}
.mark-x {
  .ant-modal-header {
    margin-bottom: 0px;
  }

  .ant-modal-content {
    .ant-modal-close {
      top: 10px;
      // background: black;
      // color: black;

      .ant-modal-close-x {
        svg {
          color: black;
        }
      }
    }
  }
}

.view-more-modal {
  max-width: 100vw !important;
  .ant-modal-title {
    color: white;
  }

  .ant-modal-header {
    background-color: #08c !important;
    padding: 5px 20px;
  }
  .ant-modal-body {
    // padding: 10px 20px;
    padding: 5px;
  }

  .ant-modal-content {
    .ant-modal-close {
      top: 6px;
      // background: black;
      // color: black;

      .ant-modal-close-x {
        svg {
          color: white;
        }
      }
    }
  }
}

.view-more-modal2 {
  max-width: 100vw !important;
  .ant-modal-title {
    color: white;
  }

  .ant-modal-header {
    background-color: #08c !important;
    padding: 5px 20px;
  }
  .ant-modal-body {
    // padding: 10px 20px;
    padding: 5px;
  }

  .ant-modal-content {
    .ant-modal-close {
      top: 4px;
      // background: black;
      // color: black;

      .ant-modal-close-x {
        svg {
          color: white;
        }
      }
    }
  }
}

.tv-iframe {
  transform-origin: center 15px;
  // @media screen and (max-width: 800px) {
  //   height: 290px;
  // }
}
.tv-score-container {
  overflow: hidden;
  margin-top: -17px;
}
