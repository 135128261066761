@import '../../../styles.module.scss';


.nav_cont {
    display: flex; 
     gap: 5px; 

     .nav-button {
        display: flex; 
        justify-content: space-between; 

        .buttons {
            display: flex; 
            background: $theme_color; 
            border: none; 
            color: white;
             padding: 8px;
            //  border-radius: 5px;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
                background: black;
            }
        }

        .button_active {
            background: $theme_color; 
            color: white;
            padding: 8px;
            cursor: pointer;
            border: none;
        }

     }
   }