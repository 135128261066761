.plus-table {
    border: 1px solid #e8e8e8;
    background-color: transparent;
    width: 100%;
    max-width: 100%;
    min-height: 150px;
    margin-bottom: 1rem;
    background-color: initial;
    .master_color {
      text-align: left;
      background-color: rgb(98, 92, 195);
      color: rgb(255, 255, 255);
      font-size: 12px;
      td {
        padding: 7px;
        border: 1px solid #e8e8e8;
      }
    }
    .agent_color{
      text-align: left;
      background-color: rgb(44, 62, 80);
      color: rgb(255, 255, 255);
      font-size: 12px;
      td {
        padding: 7px;
        border: 1px solid #e8e8e8;
      }
    }
    .sub_color{
      text-align: left;
      background-color: green;
      color: rgb(255, 255, 255);
      font-size: 12px;
      td {
        padding: 7px;
        border: 1px solid #e8e8e8;
      }
  
    }
    .admin_color{
      text-align: left;
      background-color: red;
      color: rgb(255, 255, 255);
      font-size: 12px;
      td {
        padding: 7px;
        border: 1px solid #e8e8e8;
      }
    }
    .super_color {
      text-align: left;
      background-color: rgb(3, 143, 222);
      color: rgb(255, 255, 255);
      font-size: 12px;
      td {
        padding: 7px;
        border: 1px solid #e8e8e8;
      }
    }
    .agent_color {
      text-align: left;
      background-color: rgb(44, 62, 80);
      color: rgb(255, 255, 255);
      font-size: 12px;
      td {
        padding: 7px;
        border: 1px solid #e8e8e8;
      }
    }
    .sub_agent_heading {
      border-right-width: 2px;
      border-right-color: rgb(174, 174, 174);
    }
    th {
      border: 1px solid #e8e8e8;
      padding: 7px !important;
      font-size: 12px !important;
    }
    td {
      white-space: wrap;
    }
    .border_tr {
      text-align: right;
      td {
        border: 1px solid #e8e8e8;
        padding: 4px !important;
        font-size: 12px !important;
        color: #545454;
      }
    }
  }
  table {
    border-collapse: collapse;
  }
  
  .main_table_section {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    overflow-x:scroll;
  }
  .match_table {
    .ant-checkbox-checked{
      .ant-checkbox-inner {
        background-color: #74766f !important;
        border-color: #74766f !important;
      }
    }
    
   
  }
  
  .ant-checkbox-inner{&::after{
    background-color: #74766f !important;
    
  }}
  
  .d_none{
    display: none;
  }
  
  .plus_spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  