@import '../styles.module.scss';

.logo_cont {
    width: 100%;
    height: 100%;
    // padding:  20px;
    // margin-top: 50px;
    // position: relative;
    // background: white;


    // margin-top: 40px;
    // border: 1px solid green;

    .header {
        display: flex;
        justify-content: space-between;
        // border: 1px solid green;
        align-items: center;

        .list {
            font-size: 1.6rem;
            font-weight: 500;
            font-family: "Roboto Condensed", sans-serif;
        }

        .add {
            background-color: $theme_color2;
            padding: 10px;
            color: white;
            border-radius: 5px;
        }
    }

    .users {
        margin-top: 30px;
        gap: 10px;
        width: 100% ;
            .ant-tabs-tab{
                border-bottom: 1px solid #dee2e6 ;

            }
             .ant-tabs-tab-active{
                border: 1px solid #dee2e6 ;
                border-bottom:1px solid transparent;
        }
        .ant-tabs-nav{

            &::before{
                border-bottom: 1px solid #dee2e6 !important; 
            }
        }
    }

    // .search {
    //     // margin-top: 10px;
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;

    //     .format {
    //         display: flex;
    //         align-items: center;
    //         gap: 10px;

    //         .pdf-icon {
    //             display: flex;
    //             align-items: center;
    //             background: red;
    //             padding: 8px;
    //             color: white;
    //             gap: 3px;

    //             .icon {
    //                 font-size: 18px;

    //                 svg {
    //                     font-size: 16px;
    //                 }

    //             }
                
    //             .pdf {
    //                 font-size: 16px;
    //                 letter-spacing: -.5px;

                    
    //             }
    //         }

    //         .excel-icon {
    //             display: flex;
    //             background: green;
    //             padding: 8px;
    //             color: white;
    //             align-items: center;
    //             gap: 3px;

    //             .icon {
    //                 font-size: 18px;

    //                 svg {
    //                     font-size: 16px;


    //                 }


    //             }
                
    //             .excel {
    //                 letter-spacing: -.5px;
                    
    //             }
    //         }
    //     }

    //     .input {
    //         display: flex;
    //         align-items: center;
    //         height: 100%;
    //         margin: 0;
    //     }
    // }
}

p {
    margin: 0;
}

.exposure_modals{
    .ant-modal-header {
        padding: 12px;
        background: #08c !important;
        .ant-modal-title{
            color: #fff !important;

        }
    }
    .ant-modal-close{
        color: #fff !important;
    }
}

.spin_roatet{
    animation: spin 1.5s linear infinite,
    

}

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }

