@import '../../styles.module.scss';

.account-list-header {
  display: flex;
  justify-content: space-between;

  p {
    font-weight: 500;
    font-size: 22px;
    margin-top: 0px;
  }
}
