.credit_portal-cont {
    padding: 5px;
    // border: 2px solid green;

    .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            margin: 0;
            font-size: 18px;
            font-weight: 600;
        }

       .heading_icon {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        border: none;
        background-color: #7e685a;
        color: white;
        font-size: 16px;
        cursor: pointer;
       }
    }

    .credit_portal-form {
        padding: 20px;
        // border: 2px solid yellow;
        align-items: center;

        .same {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            width: 100%;
            gap: 5px;
            align-items: center;

            label {
                margin-right: 2%;
                font-size: 13px;
                width: 30%;
                font-weight: 500;
            }
            input {
                flex: 1;
            }
            textarea {
                flex: 1;
            }
        }
    }
    .credit_buttons {
        float: right;
        button {
            background: #7e685a;
           margin-right: 10px;
           width: 80px;
           height: 30px;
            border: none;
            color: white;
            border-radius: 5px;

        }
    }
}