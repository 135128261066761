@import "../../../styles.module.scss";

.fancy_cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  height: 100%;
  // display: contents;
  .fancy-header {
    // background: $theme_color2;
    color: white;
    padding: 15px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    align-items: center;
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;

    p {
      margin: 0;
    }
  }

  .fancy-table {
    align-items: center;
    width: 100%;
    border: 1px solid #dee2e6;
    margin: 0;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    // position: relative;

    thead {
      width: 100%;
      margin-bottom: 5px;

      tr {
        padding: 0 10px;
        width: 100%;
        border: 1px solid #dee2e6;
        display: flex;
        // justify-content: flex-start;
        justify-content: space-between;
        align-items: center;

        .header {
          min-width: 40%;

          .left {
            width: 100%;
          }
        }

        .heading {
          min-width: 30%;
          display: flex;
          justify-content: space-between;
          gap: 2px;
          align-items: center;

          .no {
            padding: 10px;
            text-align: center;
            background: pink;
            font-size: 16px;
            font-weight: 800;
            color: black;
            width: 50%;
          }

          .yes {
            padding: 10px;
            background: #72bbef;
            font-size: 16px;
            font-weight: 800;
            width: 50%;
            text-align: center;
          }
        }
        .amount-cont {
          // flex: 1;
          min-width: 30%;

          .amount {
            width: 100%;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }

    tbody {
      background-color: rgba(0, 0, 0, 0.05);
      width: 100%;

      .body_tr {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 0 10px;

        .results {
          min-width: 40%;
          font-size: 12px;
          display: flex;
          flex-direction: column;
          // border: 1px solid blue;
        }

        .bet_rates {
          min-width: 30%;
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          gap: 2px;

          .data_cont {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            min-width: 90%;
            gap: 2px;
            margin: 0;
            margin-top: -2px;

            .suspended-cont {
              position: absolute;
              z-index: 10;
              background: rgba(0, 0, 0, 0.5);
              color: red;
              width: 28.5%;
              height: 45px;
              align-items: center;
              text-align: center;

              .suspended {
                width: 100%;
                height: 100%;
                font-size: 12px;
                text-align: center;
                margin-top: 10px;
              }
            }

            .back {
              text-align: center;
              width: 50%;
            }

            .span {
              display: block;
              font-size: 16px;
              padding: 5px;
              font-weight: 800;
            }
            .numb-2 {
              font-size: 12px;
              padding: 5px;
            }

            .lay {
              text-align: center;
              width: 50%;
            }
          }
        }

        .min-max {
          display: flex;
          flex-direction: column;
          padding: 5px;
          color: black;
          justify-content: flex-end;
          // flex: 1;
          min-width: 30%;

          td {
            display: flex;
            justify-content: flex-end;
            font-size: 10px;
          }
        }
      }
    }
  }
}

@media (min-width: 600px) and (max-width: 1200px) {
  .fancy-header {
    display: none;
  }
}

// .table {
//     width: 100%;
//     position: relative;
//     border: 1px solid #dee2e6;;

//     .head {
//         display: flex;
//         width: 100%;
//         justify-content: space-between;

//         .blank_tr {
//             width: 30%;
//         }

//         .head_tr {
//             flex: 1;
//             display: flex;
//             justify-content: flex-end;
//             align-items: center;
//             text-align: center;
//             gap: 2px;
//             margin-right: 14.5%
//         }
//     }

//     .body {
//         align-items: center;
//         .body_tr {
//             display: flex;
//             align-items: center;
//             justify-content: space-between;
//             height: 100%;

//             .name {
//                 width: 100%;
//                 margin-bottom: 2px;
//                 margin-right: 2px;
//                 background: rgb(230, 228, 228);
//                 padding-left: 10px;
//             }
//             .data_cont {
//                 display: flex;
//                 justify-content: space-between;
//                 align-items: center;
//                 width: 100%;
//                 height: 45px;
//                 gap: 2px;
//                 margin: 0;
//                 margin-top: -2px;

//                 .suspended-cont {
//                     position: absolute;
//                     z-index: 10;
//                     background: rgba(0,0,0,.5);
//                     color: red;
//                     width: 49.5%;
// 					height: 45px;
//                     align-items: center;
// 					text-align: center;

//                     .suspended {
//                         width: 100%;
//                         margin-top: 10px;
//                 		height: 100%

//                     }

//                 }

//                 .back-first {
//                     width: 100%;
//                     background: #72BBEF;
//                     height: 100%;

//                 }
//             }

//         }
//     }
// }
