@import '../../colors';


.heading {
    background: #7e685a;
    margin-top: 60px;
    color: white;

    p {
        // margin:  auto;
        padding: 8px 10px;
        align-items: center;
        font-size: 15px;
        font-weight: bold;
    }
  
}

.header-table {
    width: 100%;
    height: 120px;
    border: none;

    thead {
        height: 25px;

        tr {
            width: 100%;

            .runners {
                width: 40%;
                display: flex;
                justify-content: flex-start;
                font-size: 15px;
                padding-left: 10px;
                color: $color-blue-1;

            }
            .first-header-coloumn {
                width: 10%;

            }
            .second-header-coloumn {
                width: 10%;


            }
            .back-middle-header-coloumn {
                width: 10%;
                background: #72BBEF;


            }

            .lay-middle-header-coloumn {
                width: 10%;
                background: #FAA9BA;
            }

            .third-header-coloumn {
                width: 10%;
 
            }
            .fourth-header-coloumn {
                width: 10%;
            }
        }
    }

    .mathOdd_body {
        background: rgb(230, 228, 228);

        p {
            margin: 0;
        }
        .header_first_coloumn_bet {
            background: rgba(114,187,239,.5);
            text-align: center;   
        }
        .header_second_coloumn_bet {
            background: rgba(114,187,239,.75);
            text-align: center;        

        }

        .span {
            display: block;
            font-size: 16px;
            letter-spacing: -1px;
            // font-weight:700;
            // font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
           
        }
        .numb-2 {
            font-size: 10px;
            letter-spacing: -.2px;

        }

        .header_third_coloumn_bet {
            background: #72BBEF;
            text-align: center;
            
        }
        .header_fourth_coloumn_bet {
            background: #FAA9BA;
            text-align: center;

        }

        .header_fifth_coloumn_bet {
            background: rgba(250,169,186,.75);
            text-align: center;

        }

        .header_sixth_coloumn_bet {
            background: rgba(250,169,186,.5);
            text-align: center;

        }
    }


}

.bottom {
    background: #7e685aB3;;

    p {
        margin: 0;
        color: white;
        align-items: center;
        padding: 6px 8px;
       
    }
}

