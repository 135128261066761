@import '../../styles.module.scss';

.personal-cont {
    width: 50%;
    margin-right: 20px;
    .personal {
        background: $theme_color2;
        font-size: 18px;
          align-items: center;
          color: white;
          font-weight: 600;
          padding: 5px 10px;
          margin-bottom: 30px;
         
    }

    .form  {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        align-items: center;
        font-size: 14px;
        

        .pass-cont {
            position: relative;
            width: 100%;
            height: 100%;

        }

        input {
            width: 100%;
        }
        .peye {
            position: absolute !important;
            top: 70% !important;
            right: 0px !important;
            transform: translate(-50%, -50%) !important;
        }
    }

}

// .span.ant-input-group-wrapper{
// position: relative;
// }
// span.ant-input-group-addon {
//     position: absolute !important;
//     top: 50% !important;
//     right: 0px !important;
//     transform: translate(-50%, -50%) !important;
// }