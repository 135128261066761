@import "../../../styles.module.scss";

.account-cont {
  flex: 1;
  .account {
    background: $theme_color2;
    align-items: center;
    font-size: 18px;
    color: white;
    font-weight: 600;
    padding: 5px 10px;
    margin-bottom: 30px;
  }

  .form {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    gap: 20px;
    width: 100%;

    .first_row {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 100%;
      .type {
        width: 50%;
      }

      .credit {
        flex: 1;
      }
    }

    input {
      // width: 100%;
    }

    .account-cont_select {
      width: 100%;
      padding: 5px;
    }
  }
}

.user-role-select {
  .ant-select-selector {
    border-radius: 0px;
    border-color: black !important;
    height: 28px !important;
  }
}

.app-url_select {
  .ant-select-selector {
    border-radius: 0px;
    border-color: black !important;
    height: 28px !important;
  }
}

.form_create {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 10px;
  width: 100%;
  @media screen and (max-width: 800px) {
    display: block !important;
  }

  .first_row {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    @media screen and (max-width: 800px) {
      display: block !important;
    }
    .type {
      width: 50%;
      @media screen and (max-width: 800px) {
        width: 100% !important;
        margin-top: 4px;
      }
    }

    .credit {
      flex: 1;
    }
  }
}