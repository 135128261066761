.data_report{
    .ant-picker{
        width: 100%;
    }
}

.cash_data{
    .ant-col{
        margin: 7px;
        @media screen and (max-width: 800px) {
            margin: 0px;
        }
    }
    .ant-row{
        align-items: center;
        
    }
}

.report_type{
    .ant-row{
        display: block;
    }
    .ant-col{
        margin: 0px;
        text-align: left !important;
    }
   
}
.data_report{
    margin-top:6px;
}