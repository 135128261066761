// @import '../styles.module.scss';

.search {
  // margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .format {
    display: flex;
    align-items: center;
    gap: 10px;

    .pdf-icon {
      display: flex;
      align-items: center;
      background: #cb0606;
      padding: 0px 10px;
      color: white;
      // gap: 3px;
      box-shadow: 0px 5px 6px -6px #000;
      height: 34px;

      .icon {
        // font-size: 14px;

        svg {
          // font-size: 13px;
          color: white;
          margin-top: 5px;
          font-weight: bold;
        }
      }

      .pdf {
        font-size: 14px;
        letter-spacing: -0.5px;
      }
    }

    .excel-icon {
      display: flex;
      background: #217346;
      padding: 0px 10px;
      color: white;
      align-items: center;
      box-shadow: 0px 5px 6px -6px #000;
      height: 34px;

      .icon {
        // font-size: 18px;

        svg {
          // font-size: 16px;
          margin-top: 5px;
        }
      }

      .excel {
        letter-spacing: -0.5px;
        cursor: pointer;
      }
    }
  }

  .input {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0;
  }
}

// .ant-table-wrapper {
//   .ant-table{
//     background-color: red !important;
//     }
// }
.active_user-table {
  width: 100%;
  // top: 50px;
  // overflow-x: scroll;

  .even_row {
    background: rgba(0, 0, 0, 0.06);
    // &:hover {
    //   background: rgba(0,0,0,.06);
    // }
  }

  .odd_row {
    background: white;
    // background: rgba(0,0,0,.05)
  }

  .ant-table-wrapper {
    border: 1px solid #dee2e6;
  }

  .ant-table-thead {
    margin-top: 90px;
    // border: 1px solid black;

    tr {
      // padding: 40px;
      height: 40px;
      background: 1px solid black;

      .ant-table-column-title {
        margin-left: 0px;
        margin-right: 7px;
      }

      .ant-table-cell {
        white-space: nowrap;
        font-size: 14px !important;
        font-family: "Roboto Condensed", sans-serif;
        padding: 0px 4px;
        margin-left: 20px;
        height: 40px;
        font-weight: 600;
      }
    }
  }
  table {
    border-collapse: collapse !important;
  }
  .ant-table-tbody {
    border-top: 1.5px solid black;
    border-bottom: 1.5px solid black;
    border-collapse: collapse !important;
    // background-color: rgba(0, 0, 0, 0.05);
    tr {
      height: 40px;
      .even_row {
        background: rgba(0, 0, 0, 0.06);
        &:hover {
          background: red;
        }
      }
      .ant-table-cell {
        padding: 0 4px;
        font-family: "Roboto Condensed", sans-serif;
        line-height: 15px;
        border: 1px solid #dee2e6;
        font-size: 14px;
        font-weight: 500;
        color: #000000;
      }
    }
  }

  .close {
    background: red;
  }

  // .ant-modal-content {

  //   .ant-modal-close {
  //     background: red;

  //     .ant-modal-close-x {
  //       background: red;
  //     }
  // }

  // }
}

.ant-modal-close {
  .ant-modal-close-x {
    width: 30px !important;
    height: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: $theme_color2 !important;
    color: black;
    border-radius: 50%;
  }
}

.ant-checkbox-wrapper {
}
.ant-checkbox-disabled {
  .ant-checkbox-inner {
    &::after {
      border-color: rgb(248, 246, 246);
    }
  }
}
.active-user-checkbox {
  .ant-checkbox-inner:after {
    border-color: rgb(248, 246, 246);
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-radius: 0px;
    border: 1px solid black;
    &:hover {
      // border: 1px solid black !important;
      background: transparent;
    }
  }
  .ant-checkbox-input {
    &:hover {
      border: 1px solid black !important;
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #444 !important;
    }
  }
}

.tbs {
  &::-webkit-scrollbar {
    display: none;
  }
}

.logo-modal-x {
  .ant-modal-content {
    .ant-modal-close {
      top: 15px;
      // background: black;
      // color: black;

      .ant-modal-close-x {
        svg {
          color: black;
        }
      }
    }
  }
}

.partnership{
  .ant-modal-body{
    padding: 0px 12px;
  }
}

.list {
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "Roboto Condensed", sans-serif;
}
