@import "../../../styles.module.scss";

.sub-heading {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
  @media (max-width: 720px) {
    flex-direction: column;
  }
}

.transaction-password {
  width: 30%;
  @media (max-width: 720px) {
    width: 100%;
  }
}

.session-heading{
  width: 50%;
  margin-top: 18px;
  margin-bottom: 0px;
  @media screen and (min-width: 800px) {
    width: 100%;
  }
}
