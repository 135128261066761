@import "../../../styles.module.scss";

.password-cont {
  margin-top: 20px;
  position: relative;
  left: 75%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 25%;
  margin-bottom: 20px;
  @media (max-width: 720px) {
    width: 100%;
    left: 0px;
  }
  .transaction_form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 10px;
    width: 100%;
    position: relative;
    label {
    }

    input {
      width: 100%;
      padding: 15px;
    }

    .peye {
      position: absolute !important;
      top: 70% !important;
      right: 5px !important;
      transform: translate(-50%, -50%) !important;
      cursor: pointer;
    }
  }
  .create_account-button {
    background: $theme_color2;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    width: 50%;
    position: relative;
    left: 50%;

    //  justify-content: flex-end;
    //  display: flex;
  }
}
