@import "../../../styles.module.scss";

.account_state-cont {
  .account_state_heading {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;
    align-items: center;
  }
  // .form-button-div {
  //     display: flex;
  //     justify-content: space-between;
  //     width: 90%;
  //     // border: 2px solid green;

  //     .account_state_form {
  //         display: flex;
  //         justify-content: space-between;
  //         width: 100%;
  //         gap: 10px;

  //         label {
  //             font-size: 13px;
  //             font-weight: 600;
  //             font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
  //         }
  //        input {
  //             height: 2.15rem;
  //             margin-top: 10px;
  //             border-radius: 5px;
  //             border: 1px solid #ced4da;
  //             width: 200px;
  //             margin-right: 0;
  //             // border: 2px solid blue;
  //         }
  //         .account_state-date {
  //             background: #e9ecef;

  //         }
  //     }
  //     .load {
  //        margin-left: 40px;
  //        margin-right: 20px;
  //         background: #7e685a;
  //         color: white;
  //         font-weight: 400;
  //         border: none;
  //         margin-top: 20px;
  //         padding: 10px;
  //         border-radius: 5px;
  //     }
  // }

  // .account_state_bottom {
  //     margin-top: 20px;
  //     display: flex;
  //     justify-content: space-between;
  //     width: 100%;
  //     // border: 2px solid green;
  //     align-items: center;
  //     .row-selector {
  //         display: flex;
  //         gap: 5px;

  //         select {
  //             height: 20px;

  //         }

  //     }
  //     .search {
  //         display: flex;
  //         justify-content: flex-end;
  //         width: 100%;
  //         align-items: center;
  //         input {
  //             width: 150px;
  //         }
  //     }
  // }

  // .account_state-table_cont {
  //     width: 100%;
  //     border: 1px solid #dee2e6;
  //     border-bottom: none;
  //     margin-top: 10px;

  // .account_state-table {
  //     width: 100%;

  //     .account_state-head {
  //         border: 2px solid green;
  //         .account_state-head-tr {
  //             display: flex;
  //             justify-content: flex-start;
  //             padding: 10px;
  //             font-size: 14px;
  //             font-family: math;

  //             .date {
  //                 width: 10%;
  //                 display: flex;
  //                 justify-content: flex-start;

  //             }

  //             .credit {
  //                 width: 10%;

  //             }
  //             .debit {
  //                 width: 10%;
  //             }
  //             .closing {
  //                 width: 10%;

  //             }
  //             .description {
  //                 width: 15%;
  //                 // margin-left: 0;

  //             }
  //             .fromto {
  //                 width: 50%;

  //             }

  //         }
  //     }
  //     .account_state-body {
  //         width: 100%;

  //         .account_state-body-tr {
  //             border: 5px solid green;
  //             text-align: center;

  //         }

  //     }

  // }
  // .table_para_div {
  //     background: rgba(0,0,0,.05);
  //     // margin-top: 30px;
  //     border-top: 1px solid black;
  //     border-bottom: 1px solid black;

  //     .data-para {
  //         text-align: center;
  //         padding-top: 10px;
  //         padding-bottom: 10px;

  //     }
  // }
  // }
  // .account_state-bottom {
  //     display: flex;
  //     justify-content: space-between;
  //     margin-top: 5px;

  //     .account_state-button-cont {
  //         margin-right: 10px;

  //         button {
  //             padding: 10px;
  //             margin-right: 10px;
  //             background: #7e685aB3;
  //             border: none;
  //             color: white;
  //             border-radius: 5px;
  //             font-size: 14px;

  //         }
  //     }
  // }
}

// const baseUrl = "http://api.247365.exchange/admin-new-apis/report/account-statement";
// const token = '................';

// 	  headers: {
// 	    Authorization: `Bearer ${token}`
// 	  }

// 	const fetchingData = async () => {
// 		const res = await axios.get(baseUrl, headers:{ Authorization: `Bearer ${token}`});

// 	};
// 	fetchingData();
