.accountlist-table {
  .accountlist-table-container {
    width: 100%;
    overflow-x: scroll;
    background-color: white;
  }
  .format {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    // margin-bottom: 10px;

    .active-class {
      text-decoration: underline;
    }

    .pdf-icon {
      display: flex;
      align-items: center;
      background: #cb0606;
      padding: 0px 10px;
      color: white;
      // gap: 3px;
      box-shadow: 0px 5px 6px -6px #000;
      height: 34px;

      .icon {
        // font-size: 14px;

        svg {
          // font-size: 13px;
          color: white;
          margin-top: 5px;
          font-weight: bold;
        }
      }

      .pdf {
        font-size: 14px;
        letter-spacing: -0.5px;
      }
    }

    .excel-icon {
      display: flex;
      background: #217346;
      padding: 0px 10px;
      color: white;
      align-items: center;
      box-shadow: 0px 5px 6px -6px #000;
      height: 34px;

      .icon {
        // font-size: 18px;

        svg {
          // font-size: 16px;
          margin-top: 5px;
        }
      }

      .excel {
        letter-spacing: 0.5px;
      }
    }
  }

  .users_table {
    .even_row {
      background: rgba(0, 0, 0, 0.06);
    }

    .odd_row {
      background: white;
    }
  }

  .ant-table-thead {
    margin-top: 10px;
    tr {
      margin-top: 10px;
      .ant-table-cell {
        white-space: nowrap;
        font-size: 13px !important;
        // background-color: #2C3E50;
        // color: white;
      }
    }
  }
  table {
    border-collapse: collapse !important;
  }
  .ant-table-tbody {
    border-top: 1.5px solid black;
    border-bottom: 1.5px solid black;
    border-collapse: collapse !important;
    // background-color: rgba(0, 0, 0, 0.05);
    tr {
      .ant-table-cell {
        padding: 4px 5px;
        border: 1px solid #dee2e6;
      }
    }
  }
}
// .ant-modal-close {
//   .ant-modal-close-x {
//     width: 30px !important;
//     height: 30px !important;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background-color: #7e685ae6 !important;
//     color: white;
//     border-radius: 50%;
//   }
// }

.active-class_checkbox {
  .ant-checkbox-disabled {
    // .ant-checkbox-inner {
    //   &::after {
    //     border-color: rgb(238 229 229);
    //   }
    // }
  }
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-radius: 0px;
    border: 1px solid black;
    &:hover {
      // border: 1px solid black !important;
      background: transparent;
    }
  }
  .ant-checkbox-input {
    &:hover {
      border: 1px solid black !important;
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #444 !important;
    }
  }
}

.table-modal-x {
  .ant-modal-content {
    .ant-modal-close {
      top: 12px;
      // background: black;
      // color: black;

      .ant-modal-close-x {
        svg {
          color: black;
        }
      }
    }
  }
}

.more-modal-x {
  .ant-modal-header {
    padding-block: 10px;
    padding-left: 10px;
  }
  .ant-modal-content {
    .ant-modal-close {
      top: 6px;
      // background: black;
      // color: black;

      .ant-modal-close-x {
        svg {
          color: black;
        }
      }
    }
  }
}

.more-modal-more {
  .ant-modal-title {
    color: white;
  }
  .ant-modal-header {
    padding-block: 10px;
    padding-left: 10px;
    background-color: #08c !important;
  }
  .ant-modal-content {
    .ant-modal-close {
      top: 6px;
      // background: black;
      // color: black;

      .ant-modal-close-x {
        svg {
          color: white;
        }
      }
    }
  }
}

.more-modal-deposit {
  .ant-modal-title {
    color: white;
  }
  .ant-modal-header {
    padding-block: 10px;
    padding-left: 10px;
    background-color: #08c !important;
  }
  .ant-modal-content {
    .ant-modal-close {
      top: 6px;
      // background: black;
      // color: black;

      .ant-modal-close-x {
        svg {
          color: white;
        }
      }
    }
  }
}


.more-modal-withdraw {
  .ant-modal-title {
    color: white;
  }
  .ant-modal-header {
    padding-block: 10px;
    padding-left: 10px;
    background-color: #08c !important;
  }
  .ant-modal-content {
    .ant-modal-close {
      top: 6px;
      // background: black;
      // color: black;

      .ant-modal-close-x {
        svg {
          color: white;
        }
      }
    }
  }
}