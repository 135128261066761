.cont {
    width: 100%;
    height: 450px;
    background: black;


    .frame_cont {
        width: 100%;
        height: 100%;
        text-align: center;

    .iframe {
        width: 90%;
        height: 100%;
        border: none;

    }
}
}