@import '../../../styles.module.scss';

.portal_cont {
    // height: 100px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    border-radius: 10px;

    .heading {
        display: flex;
        align-items: center;
        padding: 15px;
        color: Black;
        font-size: 16px;
        font-weight: 600;
        border-top-left-radius: 9px;
        border-top-right-radius: 8.8px;

    }
}