.ant-collapse {
  border: none;

  .ant-collapse-content {
    .ant-collapse-content-box {
      padding: 10px 0 0px;
    }
  }
}

.ant-collapse-item {
  // padding: 50px;
  .ant-collapse-header {
    padding: none;
  }
}

.bets-table {
  .ant-table-tbody > tr > td {
    font-size: 12px;
    // font-weight: 600;
    padding: 2px 2px 2px 5px !important;
    //   @media (max-width: $breakpoint-mobile) {
    //     font-size: 14px;
    //   }
  }
  .ant-table-thead > tr > th {
    font-size: 12px;
    padding: 3px 2px 3px 10px !important;
    font-weight: bolder !important;
  }
  .ant-table-wrapper table {
    border-collapse: separate !important;
  }
  .ant-table-row {
    height: 35px;
    border-bottom-color: transparent !important;

    &:nth-child(3n) {
      height: 5px;
      background-color: white !important;
      box-shadow: inset 3px 0 0px transparent;
      //    border-bottom: 5px solid white !important;
    }
  }
  .ant-table-cell-row-hover {
    background-color: #72bbef57 !important;
  }
}

.bets-table {
  .ant-table-tbody > tr > td {
    font-size: 12px;
    // font-weight: 600;
    padding: 2px 2px 2px 5px !important;
    // @media (max-width: $breakpoint-mobile) {
    //   font-size: 14px;
    // }
  }
  .ant-table-thead > tr > th {
    font-size: 12px;
    padding: 3px 2px 3px 10px !important;
    font-weight: bolder !important;
  }
  .ant-table-wrapper table {
    border-collapse: separate !important;
  }
  .ant-table-row {
    height: 35px;
    border-bottom-color: transparent !important;

    &:nth-child(3n) {
      height: 5px;
      background-color: white !important;
      box-shadow: inset 3px 0 0px transparent;
      //    border-bottom: 5px solid white !important;
    }
  }
  .ant-table-cell-row-hover {
    background-color: #72bbef57 !important;
  }
}

.ant-collapse-header {
  padding: none;
  display: flex !important;
  align-items: center !important;
  // cursor: default !important;
}

.ant-collapse-expand-icon {
  svg {
    color: white !important;
  }
}

.fancy-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  margin-top: 10px;
  @media (max-width: 720px) {
    display: contents;
  }
}
