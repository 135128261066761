@import "../../../styles.module.scss";

.portal_cont {
  // height: 100px;
  // background-color:black;
  color: white;
  display: flex;
  flex-direction: column;
  // align-items: center;
  border-radius: 10px;
  // background: black;
  // overflow-y: scroll;
  // height: 400px;

  .heading {
    display: flex;
    align-items: center;
    padding: 15px;
    color: Black;
    font-size: 16px;
    font-weight: 600;
    border-top-left-radius: 9px;
    border-top-right-radius: 8.8px;
    position: sticky;
    height: 40px;
    // background-color: black;
  }
}
