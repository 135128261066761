.accountTable {
  .ant-table-body {
    // white-space: nowrap;
  }

  .ant-table-row {
    white-space: nowrap !important;
  }
}
.row-selector {
  &::-webkit-scrollbar {
    display: none;
  }
}
