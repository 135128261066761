.fancy-book-modal {
  background-color: black !important;
  .ant-modal-title {
    background-color: #3e434a !important;
    padding-block: 14px;
    // border-radius: 5px;
    color: white;
    padding-inline: 10px;
  }
  .ant-modal-content {
    background-color: #2e3337 !important;
    overflow: hidden;
    .ant-modal-close {
      top: 12px;
    }
    .ant-modal-close-x {
      color: white !important;
    }
  }
}
