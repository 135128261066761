@import '../../styles.module.scss';

.password-cont {
   width: 300px;
    float: right;
    margin-top: 20px;

    .transaction_form {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        gap: 10px;
        position: relative;

        input {
            width: 100%;
            padding: 15px;

        }
        .eye {
            position: absolute;
            top: 40px;
            left: 270px;
        }
    }
    .create_account-button {
        float: right;
        background: $theme_color2;
        color: white;
        border: none;
        padding: 10px;
        border-radius: 5px;

    }
}