@import "../../../styles.module.scss";

.sport_name {
  background: $theme_color2;
  color: white;

  align-items: center;
  padding: 10px;
  margin-top: 15px;
}

.combo {
  background: white;
  display: flex;
  align-items: center;
  padding: 15px 5px;
  width: 100%;
  border-bottom: 1px solid #dedede;
  @media (max-width: 720px) {
    justify-content: space-between;
  }
  .match_name {
    padding: 1px 0px;
    margin: 0;
    width: 40%;
  }

  .time {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 720px) {
      justify-content: flex-end;
    }
  }
}

.market_data-link {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: black;

  &:hover {
    color: black;
  }

  .heading {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    border-bottom: 1px solid #dedede;
    background: white;

    .market_combo {
      border-bottom: 1px solid #dedede;
      padding: 15px 5px;
      align-items: center;

      .market {
        margin: 0px;
        font-weight: 700;
        align-items: center;
      }

      .check-icon {
        color: rgb(160, 25, 25);
        font-size: 18px;
        position: relative;
        top: 4px;
      }
    }
    .desc {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: 15px;
      padding-right: 190px;
      padding-bottom: 15px;
      padding-left: 5px;
      align-items: center;
      @media (max-width: 720px) {
        padding-right: 10px;
      }
      .team_amount {
        display: flex;
        gap: 20px;
      }

      .team_amount2 {
        display: flex;
        margin: 0;
        gap: 20px;
      }

      .team_amount3 {
        display: flex;
        margin: 0;
        gap: 20px;
      }
    }
  }
}
