.partnership{
    .sub_partnership{
        margin-bottom: 12px;
        margin-top: 18px;
    }
    .partnership_name{
        margin-bottom: -11px;
        p{
        font-size: 16px;
        font-weight: 400 !important;
    }
    }
    .partnership_data{
        table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
            margin-top: 12px;
           
            td,
            th {
              border: 1px solid #dddddd;
              text-align: left;
              padding: 0px 8px;
            }
            th {
              background: #2C3E50;
              color: #fff;
              padding: 0 3px;
            }
            tr {
              // &:hover {
              //   background-color: #a8b3b3 !important;
              // }
              .setting_btn {
                background: rgb(82, 196, 26) !important;
                padding: 4px 7px;
                border-radius: 2px;
                color: #fff;
                font-size: 12px;
                border: unset;
                outline: unset;
              }
              p.ant-dropdown-trigger.table_dropdown {
                  position: static;
              }
              .ant-space-item {
                  background: #2C3E50;
                  color: #fff;
                  padding: 1px 7px;
                  margin-left: -17px;
                  border-radius: 3px;
                  margin-right: 7px;
              }
              .text-right{
                  text-align: right;
              }
              .text-left{
                  text-align: left;
              }
              td{
                font-size: 16px;
                font-weight: 900;
              }
      
            }
            tr:nth-child(odd) {
              background-color: #f1f1f1;
            }
          }
    } 
}