.teen_patticont {
    width: 98vw;
    height: 100vh;

    .tp-iframe {
        width: 100%;
        height: 100%;

    }

}