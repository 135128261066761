@import '../../../styles.module.scss';
// .status_heading {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     margin-bottom: 20px;

//     p {
//         margin: 0;
//         font-size: 18px;
//         font-weight: 600;
//     }

//    .heading_icon {
//     height: 35px;
//     width: 35px;
//     border-radius: 50%;
//     border: none;
//     background-color: #7e685a;
//     color: white;
//     font-size: 16px;
//     cursor: pointer;
//    }
// }



.head {
    display: flex;
    flex-direction: column;

   
.status_sub-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    p {
        margin: 0;
    }

    .active {
        color: green;

    }

    .inactive {
        color: red;

    }
}

.name {
    display: flex;
    padding: 10px 30px 10px 20px;
    justify-content: space-between;
    margin-bottom: 20px;

}


.status_slider {
    display: flex;
    align-items: center;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap: 100px;
    margin-bottom: 20px;

   .status_slider-pos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border: 2px solid green;
    margin-right: 500px;
    margin-bottom: 20px;

   }

   p {
    margin: 0;
   }

.switch_on {
    background-color: black;

}

.switch_off {
    background-color: white;

}
}



    .status_same {
        margin-top: 20px;
        display: flex;
        // justify-content: space-between;
        width: 85%;
        margin-bottom: 20px;
        padding-left: 30px;
        align-items: center;
        gap: 50px;
        // padding-right: 100px;

       

        label {
            // margin-right: 2%;
            font-size: 15px;
            width: 35%;
            font-weight: 500;
        }
        input {
            flex: 1;
        }
        
    
}
.status_buttons {
    float: right;
    display: flex;
    justify-content: flex-end;
    padding-right: 25px;
    margin-bottom: 10px;
    gap: 10px;


    .status_back {
          display: flex;
        // gap: 5px;
        // align-items: center;

        .icons {
            font-size: 18px;
            margin: 0;
            padding: 0;
            margin-top: 5px;
            margin-right: 5px;
        }

    }
    .status_submit {
        display: flex;

        .submit_icon {
            font-size: 18px;
            margin: 0;
            padding: 0;
            margin-top: 5px;
            margin-left: 10px;

        }
    }
    button {
        background: $theme_color;
    //    margin-right: 10px;
    //    width: 80px;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    align-items: center;
      
        border: none;
        color: white;
        border-radius: 5px;

    }
}

}