@import "../../../styles.module.scss";

.name_cont {
  padding-bottom: 20px;

  .input {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0;
  }

  .even_row {
    background: rgba(204, 204, 204, 0.5);
  }

  .odd_row {
    background: rgba(44, 62, 80, 0.5);
  }

  .ant-table-wrapper {
    padding: 30px;
    padding-top: 10px;
  }

  .ant-table-thead {
    tr {
      // padding: 40px;
      padding: 0px;
      height: 10px;
      background: 1px solid black;
      .ant-table-cell {
        border-radius: 0px !important ;
        &::before {
          background-color: transparent !important;
        }
      }

      th {
        padding: 0px;
        background: rgba(44, 62, 80, 0.5);
        font-size: 13px;
      }

      .ant-table-column-title {
        margin-left: 0px;
      }

      .ant-table-cell {
        white-space: nowrap;
        font-size: 14px !important;
        font-family: "Roboto Condensed", sans-serif;
        // padding: 0px 4px;
        margin-left: 20px;
        height: 25px;
        font-weight: 600;
        border-radius: 0px;

        &::before {
          position: relative;
          top: 0px;
        }
      }
    }
  }
  table {
    border-collapse: collapse !important;
  }

  .ant-table-tbody {
    border-collapse: collapse !important;
    tr {
      height: 40px;

      .ant-table-cell {
        font-family: "Roboto Condensed", sans-serif;
        line-height: 15px;
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        padding: 0px;
      }
    }
  }

  .ant-modal-close {
    .ant-modal-close-x {
      width: 30px !important;
      height: 30px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $theme_color2 !important;
      color: white;
      border-radius: 50%;
    }
  }
}

.bet-list-view-more-table {
  .ant-table-thead {
    .ant-table-cell {
      padding: 0px 10px 0px 10px !important;
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      padding: 0px 10px 0px 10px !important;
    }
  }
}
