@import url("../../../styles.module.scss");

.current_bet-form {
  //   margin-left: 15px;
  width: calc(100% - 5px);
  padding-inline: 5px;

  .current_bet-bottom {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .current_bet-select {
      margin-top: 5px;
      // width: 60%;
      //   margin-right: 5%;
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;

      .sub_select {
        width: 200px;
        padding: 4px;
        border-radius: 5px;
        @media (max-width: 720px) {
          width: 48%;
        }
      }
    }

    .radio {
      display: flex;
      align-items: center;
      width: 40%;
      gap: 10px;

      input {
        width: 20px;
      }
      label {
        position: relative;
        top: 3px;
        font-size: 15px;
      }
    }

    .load-button {
      // margin-left: 30px;
      font-size: 16px;
      // margin-right: 30px;
      margin-left: 20px;
      padding-left: 10px;
      padding-top: 7px;
      padding-bottom: 7px;
      padding-right: 10px;
      // padding: 10px;
      background: #0088cc;
      border: none;
      color: white;
      border-radius: 5px;
      width: 10%;
    }
  }
  button {
    padding: 12px;
    // margin-top: 20px;
    color: white;
    background: #0088cc;
    border: none;
    border-radius: 4px;
    // width: 10%;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
  }
}
