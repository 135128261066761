
.bottom-para {
    background: #7e685aB3;
        width: 50%;
        color: white;
        height: 30px;
        margin-top: 10px;

        
        p {
            padding-left: 10px;
            padding-top: 5px;
            // align-items: center;
            margin: 0;

        }
}

.table {
    align-items: center;
    width: 50% !important;
    border: 1px solid #dee2e6;
    margin: 0;
    height: 100%;
    margin-top: 10px;

    thead {
        width: 100%;
    
    tr {
        width: 100%;

        .header {
            width: 55%;
        }

    
        .back {
            width: 10%;
            height: 40px;
            background: #72BBEF;
            color: black;
        }

        .lay {
            width: 10%;
            height: 40px;
            background: pink;

        }
        .amount {
            width: 25%;
        }
    }
    }

    tbody {
       background-color: rgba(0, 0, 0, .05);

        tr {

            p {
                margin: 0;
            }


            .back {
                background: #72BBEF;
                text-align: center;
            }

            .lay {
                text-align: center;
                background: pink;
            }

            td {
            }
            .min-max {
                display: flex;
                flex-direction: column;
                padding: 5px;

                td {
                    display: flex;
                    justify-content: flex-end;
                    color: #17a2b8;
                    
                }
            }
        }
    }
   
    


}

