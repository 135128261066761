.cont {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    gap: 30px;

    .left {
        width: 70%;

        .dragon_cont {
            display: flex;
            flex-direction: column;

            .first_cont {
                display: flex;
                width: 100%;
                justify-content: space-between;
                background-color: #eee;
                margin-bottom: 10px;

            .first
            {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                row-gap: 20px;
                width: 100%;
                align-items: center;

                .content {
                    text-align: center;
                    padding: 10px 0;

                    p {
                        padding: 5px;
                    }

                }
        }
    }

    .second {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 20px;

        .dragon_desc-cont {
            display: flex;
            width: 50%;
            flex-direction: column;
            justify-content: space-between;
            background-color: #eee;
            margin-bottom: 10px;
            padding: 20px;
            // border: 2px solid red;

        .dragon {
            width: 100%;
            display: grid;
                grid-template-columns: 1fr 1fr;
                margin-top: 0px;
                padding-top: 0;

            .dragon-desc {
                text-align: center;
                padding: 10px 0;

                p {
                    padding: 5px;
                }

            }

        }
    }

    .tiger_desc-cont {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        background-color: #eee;
        margin-bottom: 10px;
        padding: 20px;

        .tiger {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-top: 0px;
            padding-top: 0;

            .tiger-desc {
                text-align: center;
                padding: 10px 0;

                p {
                    padding: 5px;
                }

            }

        }
    }

    }

    .third {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 20px;

        .dragon_cards {
            display: flex;
            width: 50%;
            flex-direction: column;
            // justify-content: space-between;
            background-color: #eee;
            margin-bottom: 10px;
            padding: 10px 15px;
            text-align: center;

            .desc {
                padding: 0 20px;
                display: flex;
                gap: 10px;
                flex-wrap: wrap;
                text-align: center;
                align-items: center;
                width: 100%;

                p {
                    padding: 10px 20px; 
                    text-align: center;
                    align-items: center;
                   
                }


            }

        }

        .tiger_cards {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: #eee;
            margin-bottom: 10px;
            padding: 10px 15px;

                .tiger_desc {
                    width: 100%;
                    padding: 0 20px;
                    display: flex;
                    gap: 10px;
                    flex-wrap: wrap;
                    text-align: center;
                    align-items: center;


                    p {
                        padding: 10px 20px; 
                        text-align: center;
                        align-items: center;

                    }
                }
            
        }
    }

    .forth_row {
        display: flex;
        flex-direction: column;
        background: #eee;

        .result {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 10px;
            background: #7e685aB3;
            color: white;
        }

    }

    }
}

    .right {
        flex: 1;

    }

}

.icon {
    background: black;
    color: white !important;
    font-size: 20px;
    width: 60%;
    // padding: 10px;
    // width: 50%;
}