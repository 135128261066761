@import '../../../styles.module.scss';


.partnership-cont {
    width: 100%;
    margin-top: 30px;
    // height: 100%;

    .partnership {
        background: $theme_color2;
        font-size: 18px;
        align-items: center;
        color: white;
        font-weight: 600;
        padding: 5px 10px;
        margin-bottom: 20px;

    }
    .partnership-total {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        align-items: center;
        border: 1px solid #dee2e6;
        
        .partnership-breakdown {
            width: 30%;
            border-right: 1px solid #dee2e6;

        }
        .partnership-amount {
            flex:1;

           
        }

    }



.upline {
    background: grey;
    padding: 10px;
    background: rgba(0,0,0,.05);
    // margin-top: 0;
  

}

.downtime {
    background: white;
    padding: 10px;
    border: none;
    height: 35px;
    width: 100%;
   
}
.downtime1{
    background: white;
    padding: 10px;
    border: 1px solid #000;
    height: 35px;
    width: 100%;
}
.our {
    background: grey;
    padding: 10px;
    background: rgba(0,0,0,.05);
   

}


}