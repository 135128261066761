@import url("../styles.module.scss");

.cont {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: auto;
  width: 96%;
  margin: auto;
  justify-content: flex-start;
  // margin-left: 10px;
  padding: 10px;

  .sub_button {
    background-color: #2c3e50;
    border: none;
    color: white;
    &:hover {
      color: white;
      background: #2c3e50;
    }
  }
}
