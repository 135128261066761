.table_cont {
  display: flex;
  justify-content: space-between;

  .current_bet_bottom {
    display: flex;
    justify-content: space-between;
    // border: 2px solid green;
    width: 100%;
    margin-bottom: 20px;

    .row-selector {
      display: flex;
      align-items: center;
      gap: 5px;
      padding-left: 20px;
    }

    .search {
      display: flex;
      align-items: center;
      label {
        margin-right: 10px;
      }
    }
  }
}

.current_bet-bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;

  .current_bet-button-cont {
    display: flex;
    gap: 10px;

    button {
      border: none;
      border-radius: 5px;
      background: #7e685a;
      color: white;
      padding: 10px 10px;
    }
  }
}
