@import "../../../../styles.module.scss";

.table {
  width: 100%;
  position: relative;
  // border: 1px solid #dee2e6;

  .head {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .blank_tr {
      width: 40%;
    }

    .head_tr {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 2px;
      width: 60%;
    }
  }

  .body {
    align-items: center;
    .body_tr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      // margin-bottom: 2px;
      background: rgba(0, 0, 0, 0.05);

      .name {
        width: 40%;
        margin-bottom: 2px;
        margin-right: 2px;
        // background: rgb(230, 228, 228);
        padding-left: 10px;
      }
      .data_cont {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 60%;
        height: 45px;
        gap: 2px;
        margin: 0;
        margin-top: -2px;
        // background-color: #57cf1f;
        position: relative;
        .suspended-cont {
          position: absolute;
          z-index: 10;
          background: rgba(0, 0, 0, 0.5);
          color: red;
          width: 100%;
          height: 100%;
          align-items: center;
          text-align: center;

          .suspended {
            width: 100%;
            margin-top: 10px;
            height: 100%;
          }
        }

        .back-first {
          width: 100%;
          background: "";
          height: 100%;
        }

        .back-third {
          width: 100%;
          // background: #72BBEFA3;
          height: 100%;
          text-align: center;
          padding: 5px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .lay_first {
          width: 100%;
          background: #f994ba;
          height: 100%;
          text-align: center;
          padding: 5px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }
}
