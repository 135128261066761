@import "../styles.module.scss";

@keyframes sider {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.main-layout {
  width: 100%;
  height: 100vh;
  background-color: white;
  @media screen and (max-width: 750px) {
    height: calc(100vh - 87px);
  }
}
.antd-header-comp {
  // padding-inline: 5px;
  // position: sticky;
  top: 0%;
  background-color: $theme_color;
  // height: 52px;
  z-index: 100;
  padding: 0;
  overflow-y: scroll;
}
.ant-layout-header {
  line-height: 0px;
  height: auto !important;
}
.antd-sider-comp {
  background-color: #f9f9f9 !important;
  animation: sider;
}
.ant-drawer-mask {
  background-color: transparent !important;
}
.match_drawer-comp {
  background-color: #f9f9f9;
  height: calc(100% - 110px);
  margin-top: 110px;
}
.antd-drawer-comp {
  background-color: #f9f9f9;
  height: calc(100% - 50px);
  margin-top: 50px;

  .ant-drawer-header {
    border: none;
  }
  .ant-drawer-body {
    padding: 0px;
  }
}

.ant-drawer-content-wrapper {
  box-shadow: none !important;
}
.content-comp {
  padding: 10px 5px 0px 5px;
  height: 100%;
  // overflow-x: hidden;
  margin-bottom: 24px;
  overflow-y: scroll;
  background-color: white;
}
