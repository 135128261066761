@import "../../../styles.module.scss";

.personal-cont {
  width: 50%;
  @media (max-width: 720px) {
    width: 100%;
  }
  .personal {
    background: $theme_color2;
    font-size: 18px;
    align-items: center;
    color: white;
    font-weight: 600;
    padding: 5px 10px;
    margin-bottom: 30px;
  }

  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    align-items: center;
    font-size: 14px;

    .pass_field {
      width: 100%;
      height: 100%;
      position: relative;
      // border: 2px solid green;

      .peye {
        position: absolute !important;
        top: 70% !important;
        right: 5px !important;
        cursor: pointer;
        transform: translate(-50%, -50%) !important;
      }
    }

    input {
      width: 100%;
    }
  }
}
