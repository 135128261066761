// .user_profile_cont {
//     height: 100%;
//     display: flex;
//     flex-direction: column;

.user_profile-combo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  // width: 700px;
  @media (max-width: 720px) {
    flex-direction: column;
  }
  .user_profile-sub_combo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // width: 80%;
    gap: 20px;

    .info {
      background: white;
      height: 300px;
      @media (max-width: 720px) {
        height: 100px;
      }
      display: flex;
      flex-direction: column;
      text-align: center;

      .info_top {
        margin: auto;
        border-bottom: 1px solid #eff2f7;
        border-width: 100%;
        width: 100%;

        button {
          background: black;
          color: white;
          border-radius: 50%;
          padding: 15px;
          border: none;
        }
      }

      .info_bottom {
      }
    }

    .partnership {
      height: 100%;
      // border: 1px solid red;
      background: white;
      // padding: 5px;
      padding: 5px 15px;

      .partnership_details {
        display: flex;
        padding-right: 50px;
        justify-content: space-between;
        width: 100%;
        gap: 30px;

        .part_desc {
          width: 40%;
          font-weight: bold;
          margin-bottom: 5px;
        }

        .part_info {
          flex: 1;
        }
      }
    }
  }
  .user_profile-additional_info {
    flex: 1;
    background: white;
    padding-left: 20px;
    // padding-right: 200px;
    padding-right: 100px;

    h2 {
    }

    .add_info-details {
      .add_info-sub_details {
        display: flex;
        // width: 500px;
        min-width: auto;
        align-items: center;

        .add_desc {
          display: flex;
          width: 50%;
          justify-content: flex-start;
          font-weight: bold;
          margin-bottom: 5px;
          font-size: 14px;
        }

        .add_info {
          display: flex;
          flex: 1;
          justify-content: flex-start;
        }
      }
    }
  }
}
// }
