.binary_cont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .table {
        width: 100%;
        height: 100%;
        // border: 1px solid #dee2e6;
        display: flex;
        // flex-direction: column;
        justify-content: space-between;
        gap: 10px;
        margin-bottom: 10px;

        .left {
            width: 50%;
            border: 1px solid #dee2e6;
            display: flex;
            flex-direction: column;

        .table_head {
            display: grid;
            width: 100%;
            grid-template-columns: 50% 1fr 1fr 1fr;
            border-bottom: 1px solid white;
            
            background: rgb(246, 245, 245);

            p {
                text-align: center;
                padding: 10px 0px;
                border-right: 1px solid white;
                // border: 1px solid grey;
            }
        }

        .table_data {
            display: flex;
            // justify-content: space-between;
            flex-direction: column;

            .table-desc {
                // margin-top: 5px;
                display: grid;
                grid-template-columns: 50% 1fr 1fr 1fr;
                border-bottom: 1px solid white;
                // column-gap: 5px;

                p {
                    text-align: center;
                }

                .numbers {
                    display: flex;
                    flex-direction: column;
                    border-right: 1px solid white;
                    background: #72BBEF ;
                    padding: 5px;

                    .numb_top {

                    }

                    .numb_bottom {

                    }
                }

            }
        }
    }

    .right-table  {
        flex: 1;
        display: flex;
        flex-direction: column;
        border: 1px solid #dee2e6;

        .table_head {
            display: grid;
            width: 100%;
            grid-template-columns: 50% 1fr 1fr 1fr;
            border: 1px solid white;
            background: rgb(246, 245, 245);

            p {
                text-align: center;
                padding: 10px 0;
                // border: 1px solid grey;
            }
        }
        .table_data {
            display: flex;
            // justify-content: space-between;
            flex-direction: column;

            .table-desc {
                // margin-top: 5px;
                display: grid;
                grid-template-columns: 50% 1fr 1fr 1fr;
            border-bottom: 1px solid white;

                p {
                    text-align: center;
                }

                .numbers {
                    display: flex;
                    flex-direction: column;
                    border-right: 1px solid white;
                    background: #72BBEF ;
                    padding: 5px;


                    .numb_top {

                    }

                    .numb_bottom {

                    }
                }

            }
        }
    }
    }

    .right {
        flex: 1;
    }


}