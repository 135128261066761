@import "../styles.module.scss";

.login_page-cont {
  height: 100vh;
  width: 100vw;
  // background: $theme_color;
  background: linear-gradient(#0088cc, #2c3e50);

  .login_page-sub-cont {
    margin: auto;
    width: 25%;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 720px) {
      width: 80%;
    }
    .login_page-heading {
      width: 60%;
      margin-bottom: 20px;

      img {
        width: 100%;
        margin: auto;
      }
    }

    .login_page-details-cont {
      width: 100%;
      background: #eee;
      align-items: center;
      border-radius: 5px;
      padding: 0 20px;
      position: relative;

      .login_page-details {
        display: flex;
        flex-direction: column;
        padding: 20px;
        align-items: center;
        // width: 100%;
        gap: 20px;

        .login_page-details_signIn {
          font-size: 1.5rem;
          margin-bottom: 10px;
          // margin-top: 5px;
        }

        .login_page-input {
          border: 1px solid #ced4da;
          width: 100%;
          padding: 5px 10px;
        }

        .login_page-login {
          background: $theme_color;
          border: none;
          color: white;
          padding: 10px;
          font-size: 14px;
          width: 100%;
          margin: 0;
          display: flex;
          justify-content: center;

          .login {
            // margin-right: 40px;
          }
        }
      }
    }

    .tagline {
      color: white;
      margin-top: 15px;
    }
  }
}
