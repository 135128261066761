@import "../../../styles.module.scss";

.left_table-cont {
  width: 100%;
  position: relative;

  .left_table-heading {
    padding: 9px;
    background: $theme_color2;
    margin-bottom: 10px;

    .heading {
      display: flex;
      justify-content: space-between;
      color: white;
    }
  }

  .ant-collapse-item {
    padding: 0px;
    .ant-collapse-header {
      padding: 0px;
    }
  }

  .table_left-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 15px 10px;
    // border: 1px solid green;

    .left {
      align-items: center;
      display: flex;
      gap: 5px;
    }

    .session {
      background: $theme_color;
      font-size: 15px;
      padding: 10px;
      color: #fff;
      border-radius: 5px;
    }

    .right {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .match_odds-cont {
    display: flex;
    flex-direction: column;
    width: 100%;

    .match-odds {
      background: #3c444b;
      color: white;
      padding: 5px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      margin-bottom: 20px;
      // display: flex;
      // justify-content: space-between;

      .match-odds_desc {
        display: flex;
        justify-content: space-between;
        margin-left: 10px;

        .user_book {
          padding: 10px;
          background: rgb(241, 133, 33);
          border-radius: 5px;
          margin-right: 10px;
        }
      }
    }
  }

  .mathOdd_table {
    width: 100%;
    height: 120px;
    // border: 1px solid #dee2e6;
    // margin-top: 10px;

    .mathOdd_head {
      height: 25px;

      .mathOdd_tr {
        width: 100%;

        .min_heading {
          width: 50%;
          display: flex;
          justify-content: flex-start;
          color: #17a2b8;
          font-size: 15px;
          padding-left: 10px;
          padding-top: 5px;
          line-height: 1;
        }
        .first {
          width: 10%;
        }
        .second {
          width: 10%;
        }
        .back-middle {
          width: 10%;
          background: #72bbef;
          border: 1px solid #fff;
          font-size: 16px;
    font-weight: bold;
        }
        .lay-middle {
          width: 10%;
          background: #F994BA;
          border: 1px solid #fff;
          font-size: 16px;
    font-weight: bold;

        }
        .third {
          width: 10%;
        }
        .fourth {
          width: 10%;
        }
      }
    }
    p {
      margin: 0;
    }
    .mathOdd_body {
      // background: rgb(191, 187, 187);
      // background: rgb(230, 228, 228);
      background: rgba(0, 0, 0, 0.05);
      // border: 2px solid green;

      .mathOdd_body_tr {
        // border: 2px solid yellow;

        .name {
          padding-left: 10px;
          font-size: 14px;

          width: 40%;
          //   white-space: n;
        }

        .first-middle-0 {
          background: rgba(114, 187, 239, 0.5);
          text-align: center;
          // display: flex;
          // flex-direction: column;
          // text-align: center;
          // padding:14px 10px;
          // gap: 5px;
        }

        .first-middle-1 {
          background-color: rgba(114, 187, 239, 0.75);
          text-align: center;
        }
        .first-middle-2 {
          background-color: rgb(114, 187, 239);
          text-align: center;
        }

        td:hover {
          // background: rgb(75, 73, 73);
        }

        .lay_data-0 {
          background: #faa9ba;
          text-align: center;
          padding: 10px;
        }

        .lay_data-1 {
          background: rgba(250, 169, 186, 0.75);
          text-align: center;
        }

        .lay_data-2 {
          background: rgba(250, 169, 186, 0.5);
          text-align: center;
        }
        .span {
          display: block;
          font-size: 16px;
          font-weight: 800;

          font-family: "Roboto Condensed" sans-serif;
        }
        .numb-2 {
          font-size: 12px;
        }
        .back-middle {
          background: #72bbef;
          text-align: center;
        }
        .lay-middle {
          background: #faa9ba;
          text-align: center;
        }
        .third-middle {
          background: rgba(250, 169, 186, 0.75);
        }

        .fourth-middle {
          background: rgba(250, 169, 186, 0.5);
        }
      }
    }
  }
}

.modal-x {
  .ant-modal-content {
    .ant-modal-close {
      top: 15px;
      // background: black;
      // color: black;

      .ant-modal-close-x {
        svg {
          color: black;
        }
      }
    }
  }
}
