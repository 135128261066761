.account_hitory-table {
  border-radius: 10px;
    .ant-table-thead {


      tr {
        .ant-table-cell {
          white-space: nowrap;
          font-size: 11px !important;


        }
      }
    }
    table {
      border-collapse: collapse !important;


    }
    .ant-table-tbody {
      border-top: 1.5px solid black;
      border-bottom: 1.5px solid black;
      border-collapse: collapse !important;
      background-color: rgba(0, 0, 0, 0.05);

      tr {
        .ant-table-cell {
          padding-top: 8px;
          padding-bottom: 8px;
          border: 1px solid #dee2e6;


        }
      }
    }
  }
  