@import "../../../styles.module.scss";

.profit_loss-cont {
  display: flex;
  // margin-right: 20px;
  width: calc(100% - 5px);
  padding-inline: 5px;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 720px) {
    flex-direction: column;
  }
  .select-match {
    width: 15%;
    @media (max-width: 720px) {
      width: 100%;
    }
  }
  button {
    margin-top: 23px;
    color: white;
    background: $theme_color;
    border: none;
    border-radius: 4px;
    width: 10%;
  }
}
.profit_loss-date {
  width: calc(100% - 5px);
  margin-block: 10px;
  padding-inline: 5px;
}
