.main_card_section {
    display: flex;
    flex-direction: row;
    align-items: center;
    .icon_card_section {
      margin-right: 1rem !important;
      svg {
        font-size: 50px;
        color: #fff;
      }
    }
  
    p {
      color: #fff;
      font-size: 16px;
      font-weight: 600;
    }
  }
  
  @media screen and (max-width: 800px) {
    .ant-card {
      margin-bottom: 20px !important;
      .ant-card-grid {
        width: 43%;
        text-align: left !important;
        padding: 15px 20px !important;
        .icon_card_section {
          svg {
            font-size: 30px !important;
          }
        }
      }
    }
    .f-w {
      h2 {
        font-size: 16px !important;
      }
    }
  }
  
  @media screen and (min-width: 300px) and (max-width: 350px) {
    .ant-card-grid {
      width: 100% !important;
      text-align: left !important;
    }
  }
  @media screen and (min-width: 351px) and (max-width: 800px) {
    .ant-card-grid {
      width: 43% !important;
      text-align: left !important;
    }
  }
  
  .switch_btn1 {
    margin-bottom: 12px;
  
    .ant-row.ant-form-item-row {
      width: 90%;
      min-width: 90%;
      max-width: 90%;
      display: flex;
      // flex-wrap: wrap;
      justify-content: space-between !important;
    }
  
  
    .ant-col.ant-form-item-control {
      flex-grow: unset;
      flex: 0 0;
    }
  
    .ant-switch {
      height: 20px;
      min-width: 39px;
    }
  
    .ant-switch .ant-switch-handle {
      width: 16px;
      height: 16px;
    }
  }
  
  .modal_dash {
    .ant-modal-content {
      padding: 0px 0px !important;
      .ant-modal-title{
        background-color: #08c;
        h1{
            padding: 12px;
            color: #fff;
          font-size: 18px;
        }
      }
      .ant-modal-body {
        padding: 12px;
    }
    }
    .ant-modal-close{
        top: 10px;
        .ant-modal-close-x{
            color: #fff;
            font-weight: 600;
        }
    }
  }
  .tital_card_section{
    line-height: 0.9;
  }