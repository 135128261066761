@import '../../styles.module.scss';

.addaccount-cont {
    // height: 100%;
    overflow-y: scroll;
    // border: 2px solid green;
    z-index: 1;
    box-shadow:  2px 2px 20px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 20px;
    position: relative;
    .addaccount-heading {
        font-size: 30px;
        margin-bottom: 20px;

    }
  

    }
  
    .min-max-bet-cont {
        width: 100%;
        margin-top: 30px;
        margin-bottom: 20px;

        .min-max-bet {
            background: $theme_color2;
            font-size: 18px;
            align-items: center;
            color: white;
            font-weight: 600;
            padding: 5px 10px;
            margin-bottom: 20px;

        }

        .cont {
            border: 1px solid #dee2e6;
            align-items: center;

        .blank-div {
            width: 100%;
            height: 20px;
            background: white;

        }

        .min-max-bet-desc {
            display: flex;
            justify-content: flex-start;
            

            .bet-breakdown {
                width: 30%;
                background: rgba(0, 0, 0, 0.05);
                display: grid;
                grid-template-rows: 1fr 1fr 1fr;
               
                .bet {
                    padding-top: 30px;
                    padding-bottom: 30px;
                    padding-left: 10px;
                    border-bottom:2px solid #dee2e6;
                    border-right: 2px solid #dee2e6;

                }

                .max-bet {

                }

                .delay {

                }

            }

            .bet-amount {
                // display: flex;
                justify-content: space-between;
                flex-direction: column;
                flex: 1;

                .min_bet {
                    display: flex;
                    flex-direction: column;
                    .min_bet-upper {
                        background: rgba(0, 0, 0, 0.05);
                        // height: 28px;
                        padding: 0.75rem;

                    }
                    .min_bet-bottom {
                        // height: 28px;
                        padding: 0.75rem;

                    }
                }
            }

        }
    }
}

p {
    margin: 0;
}




