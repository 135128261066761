@import "../styles.module.scss";

.pass_reset-cont {
  height: 100vh;
  width: 100vw;
  // background: $theme_color;
  background: linear-gradient(#0088cc, #2c3e50);

  .pass_reset-sub-cont {
    margin: auto;
    width: 25%;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 720px) {
      width: 80%;
    }

    .pass_reset-heading {
      width: 60%;
      margin-bottom: 20px;

      img {
        width: 100%;
        margin: auto;
      }
    }

    .pass_reset-details-cont {
      width: 100%;
      background: #eee;
      align-items: center;
      border-radius: 5px;
      padding: 0 20px;

      .pass_reset-details {
        display: flex;
        flex-direction: column;
        padding: 20px;
        align-items: center;
        // width: 100%;
        gap: 20px;
        .password_update_message {
          font-size: 14px;
          background: #f8d7da;
          color: #721c24;
          width: 100%;
          padding: 10px;
        }

        p {
          font-size: 1.5rem;
          margin-bottom: 10px;
          margin-top: 20px;
        }

        .pass_reset-input {
          border: 1px solid #ced4da;
          width: 100%;
          padding: 5px 10px;
        }

        .pass_reset-confirm {
          background: $theme_color;
          border: none;
          color: white;
          padding: 10px;
          font-size: 14px;
          width: 100%;
          margin: 0;
        }
      }
    }
    .tagline {
      color: white;
      margin-top: 15px;
    }
  }
}
