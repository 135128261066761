.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pink {
  box-shadow: inset 3px 0 0px rgb(254, 130, 130);
  background-color: #ffb4d0;
}
.blue {
  background-color: #a3e1fa;
  box-shadow: inset 3px 0 0px #3cc1f6;
}
button {
  cursor: pointer;
}

.loader-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-self: center;
  z-index: 2147483645;
  display: flex;
  justify-content: center;
  background-color: rgba(256, 256, 256, 0.3) !important;
}
.back{
  background-color: rgb(114, 187, 239);
}

.lay{
 
    background-color: rgb(249, 148, 186);
  
}

.d-none{
  display: none;
}


@media screen and (max-width: 800px) {
  .dis-none{
    display: none;
  }
}

::-webkit-scrollbar {
  display: none;
}
.ant-select-selection-placeholder {
  color: #000 !important;
}

.user_boook{
  justify-content: flex-end;
  @media screen and (max-width: 800px) {
    justify-content: center;
    white-space: nowrap;
    flex-wrap: wrap;
  }
}

.spin_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}

.ant-spin-dot {
  width: 1.8rem !important;
  height: 1.8rem !important;
}