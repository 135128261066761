.pagination {
  width: 100%;
  color: black;
  justify-content: end;
  display: flex;
  margin-top: 10px;
  ul {
    margin: 0%;
    padding-left: 0%;
    display: flex;
    align-items: center;
    gap: 30px;
    li {
      list-style: none;
      .forward-pagination {
        cursor: pointer;
        font-size: 22px;
        color: rgb(72, 72, 72);
      }
      .backward-pagination {
        cursor: pointer;
        font-size: 22px;
        color: rgb(72, 72, 72);
      }
      .page-index-btn {
        width: 30px;
        height: 30px;
        border-radius: 100px;
        border: 1px solid black;
        background-color: black;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
