@import '../../../styles.module.scss';

.acc-cont {


    .account_state_bottom {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        // border: 2px solid green;
        align-items: center;
        margin-bottom: 10px;

        .row-selector {
            display: flex;
            gap: 5px;

            select {
                height: 20px;

            }

        }

        .search {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            align-items: center;

            input {
                width: 150px;
            }
        }
    }

    .account_state-table_cont {
        width: 100%;
        border: 1px solid #dee2e6;
        border-bottom: none;
        margin-top: 10px;

        .account_state-table {
            width: 100%;

            .account_state-head {
                border: 2px solid green;

                .account_state-head-tr {
                    // display: flex;
                    // justify-content: flex-start;
                    display: grid;
                    grid-template-columns: 15% repeat(4, 1fr) 50%;
                    align-items: center;
                    padding: 10px;
                    font-size: 14px;

                }
            }

            .account_state-body {
                width: 100%;

                .account_state-body-tr {
                    border: 5px solid green;
                    text-align: center;

                }

            }

        }

        .table_para_div {
            background: rgba(0, 0, 0, .05);
            // margin-top: 30px;
            border-top: 1px solid black;
            border-bottom: 1px solid black;


            .data-para {
                text-align: center;
                padding-top: 10px;
                padding-bottom: 10px;


            }
        }
    }

    .account_state-bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        margin-bottom: 60px;

        .account_state-button-cont {
            margin-right: 10px;

            button {
                padding: 10px;
                margin-right: 10px;
                background: $theme_color;
                border: none;
                color: white;
                border-radius: 5px;
                font-size: 14px;

            }
        }
    }



    .ant-modal-close {
        top: 8px !important;

        .ant-modal-close-x {
            // background: black;
            background: black;

            svg {
                // margin-bottom: 50px;
            }
        }
    }

}

.bet-list-modal {
    .ant-modal-content {
        .ant-modal-close {
            top: 8px;
        }
    }
}