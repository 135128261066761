@import '../../styles.module.scss';

.btn-comp {
  border: none;
  background-color: $theme_color2;
  color: white;
  &:hover {
    background-color: $theme_color;
    color: white !important;
  }
}
