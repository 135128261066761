@import '../../../styles.module.scss';
    .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        padding: 20px;
        padding-bottom: 0px !important;

        p {
            margin: 0;
            font-size: 18px;
            font-weight: 600;
        }

       .heading_icon {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        border: none;
        // background-color: #7e685a;
        color: white;
        font-size: 16px;
        cursor: pointer;
       }
    }

    .deposit_portal-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;

        .same {
            display: flex;
    justify-content: space-between;
    align-items: center;
 width: 100%;
 gap: 5px;
    margin-bottom: 10px;

    .deposit-eye {
        position: absolute;
        right: 40px;
    }

            .deposit_portal-form_input {
                width: 100%;
                // margin-right: 10px;
            }

            label {
                // margin-right: 2%;
                font-size: 13px;
                width: 30%;
                font-weight: 500;
            }
            input {
                flex: 1;

            }
            textarea {
                flex: 1;
            }
        }
    }
    .deposit_buttons {
        width: 100%;
        // display: flex;
        // float: right;
        display: flex;
        flex-direction: row-reverse;
        gap: 10px;
        
        button {
            background: $theme_color2;
            float: right;
        //    margin-right: 10px;
           width: 80px;
           height: 30px;
            border: none;
            color: white;
            border-radius: 5px;

        }
        .deposit_submit {
            // margin-right: 20px;
        }
    }
