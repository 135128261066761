.date_sec {
  display: flex;
  justify-content: start;
  align-items: center;
  @media screen and (max-width: 800px) {
    display: block;
  }
  .sub_date_sec {
    margin: 12px;
    width: 20%;
    @media screen and (max-width: 800px) {
      width: 100%;
      margin: 0px;
    }
    .ant-picker {
      width: 100%;
    }
  }
}

.table_section {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  overflow: scroll;
  th {
    position: relative;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    text-align: start;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.2s ease;
    padding: 11px 16px;
    white-space: nowrap;
  }
  tr {
    &:hover{
        background: #fafafa;
    }
    td {
      transition: background 0.2s, border-color 0.2s;
      border-bottom: 1px solid #f0f0f0;
      position: relative;
      padding: 10px 16px;
      white-space: nowrap;
    }
  }
  .table_dropdown {
    background: #08c;
    color: #fff;
    text-align: center;
    padding: 3px 1px 0px 1px;
    @media screen and (max-width: 800px) {
      padding: 4px 8px 2px 8px;
    }
}
.text-right{
    text-align: right !important;
}
}

.myBet{
  display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 34px;
    width: 23%;
    @media screen and (max-width: 800px) {
      width: 100%;
      margin: 0px 20px;
    }

  .ant-form-item{
    display: flex;
    .ant-row{
      display: block;
  }
  }
  .ant-select-selector{
    width: 200px !important;
  }
  .submit_btn {
    background: #2c3e50;
    color: white;
    font-weight: 400;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    margin-top: 29px;
    @media screen and (max-width: 800px) {
      margin-top: 40px;
    }
  }
}

.match_bet{
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
}

.casino_rept{
  margin-bottom: 0px !important;
}

.sport_selector{
  display: flex;
  margin-top: 24px;
}


@media screen and (max-width: 800px) {
  .ant-dropdown.ant-dropdown-placement-rightTop{
    width: 89% !important;
  }

  .sport_list{
    li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child{
      font-size: 16px !important;
    }
  }
  
}