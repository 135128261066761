.active_match_name {
    .ant-card-head-title {
      font-size: 20px !important;
    }
    
  }
  .ant-card-head{
    height: 100%;
    min-height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    .ant-card-head-wrapper {
        background: #08c;
        color: #fff;
        padding: 14px;
    }
  }
  .ant-card-body{
    padding: 0px !important;
  }
 
  .inplay {
    background-color: rgb(50, 46, 115);
    outline: unset;
    border: unset;
    color: #fff;
    font-size: 12px;
    border-radius: 2px;
    padding: 4px 9px;
  }
  .loading_active {
    margin-top: 5%;
  }
  
  .active_slip.login_report {
    margin: 0px 11px;
  }
  
  .active_match_table{
    height: 100%;
    overflow-x: scroll;
    overflow-y: unset !important;
  }
  
  
  .active_sport_list {
    padding: 3px 2px;
    font-size: 16px;
    color: #fff;
    .sub_list_sport_list {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: #2C3E50;
      overflow-x: scroll;
      white-space: nowrap;
      div{
        padding: 4px 12px;
        border-left: 1px solid #fff;
        cursor: pointer;
      }
      .activeList{
        background-color: #08c;
      }
    }
  }