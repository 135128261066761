.sport-view-button {

        .ant-modal-content {
            .ant-modal-close {
                top: 12px;
                // background: black;
                // color: black;

                .ant-modal-close-x {
                svg {
                    color: black;
                    
                }
                }
            }
        }
    

}