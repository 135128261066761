@import "../../../styles.module.scss";

@keyframes hiding2 {
  from {
    opacity: 0;
    height: 0px;
    margin: 0px 0px;
  }
  to {
    opacity: 1;
    height: 3px;
    margin: 2px 0px;
  }
}

@keyframes hiding {
  from {
    opacity: 1;
    height: 3px;
    margin: 2px 0px;
  }
  to {
    opacity: 0;
    height: 0px;
    margin: 0px 0px;
  }
}
@keyframes alert {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
// .header_cont {
//   display: flex;
//   flex-direction: column;
//   min-height: 100px;
//   align-items: center;
//   border: 1px solid red;
//   padding: 0;
//   background-color: $theme_color;

.ant-layout .ant-layout-header {
  overflow: hidden;
}

.ant-layout {
  @media screen and (max-width: 800px) {
    margin-bottom: 12px;
    
  }
}

.nav-container {
  width: calc(100vw - 10px);
  // height: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  // min-width: 900px;
  width: 100%;
  padding: 5px 0;

  // border: 1px solid red;
  .nav-left-col {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 10px;
    .logo {
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;
      img {
        max-width: 120px;
        height: 40px;
      }
    }
    .sider-toggle-icon {
      display: flex;
      align-items: center;
      flex-direction: column;

      cursor: pointer;
      .bar {
        width: 20px;
        height: 3px;
        // margin: 2px 0;
        transition: 0.4s;
        background-color: white;
      }
      .bar2 {
        // display: none;
        animation: hiding2 0.4s forwards ease-in;
      }
    }
    .sider-toggle-icon2 {
      display: flex;
      align-items: center;
      flex-direction: column;
      cursor: pointer;

      .bar {
        width: 20px;
        height: 3px;
        margin: 2px 0;
        transition: 0.4s;
        background-color: white;
      }
      .bar1 {
        width: 20px;
        height: 3px;
        margin: -1px 0;
        transition: 0.4s;
        background-color: white;
        transform: rotate(-50deg);
      }
      .bar2 {
        animation: hiding 0.2s forwards ease-in;
      }

      .bar3 {
        width: 20px;
        height: 3px;
        margin: -2px 0;
        transition: 0.4s;
        background-color: white;
        transform: rotate(50deg);
      }
    }
    .nav-menu {
      height: 100%;
      display: flex;
      align-items: center;
      width: 100%;
      font-family: "Roboto Condensed", sans-serif;
      font-size: 14px;
      @media (max-width: 720px) {
        display: none;
      }
      ul {
        margin-left: 0px;
        align-items: center;
        display: flex;
        gap: 20px;
        height: 100%;
        padding-left: 10px;
        margin-bottom: 0px;
      
    //       white-space: nowrap;
    // flex-wrap: wrap;
          
       
        margin-top: 0px;

        li {
          color: white;
          list-style: none;
          // height: 100%;
          // font-size: 0.9rem;
          font-weight: 600;
          letter-spacing: -1px;
          display: flex;
          align-items: center;
          gap: 5px;
          cursor: pointer;
          letter-spacing: 0.2px;
          line-height: 16px;

          svg {
            margin-bottom: 5px;
          }

          a {
            color: white;
            letter-spacing: 0.01px;
            // @media screen and (min-width: 700px) and (max-width:1100px) {
            //   width: 90px;
            // }
            
            &:hover {
              text-decoration: underline;
            }
          }
          &:nth-child(2) {
            // animation: alert 1s forwards ease-in infinite;
          }
          span {
            padding-top: 5px;
          }
        }
      }
    }
  }
  .nav-right-col {
    height: 100%;
    // height: 100px;
    display: flex;
    align-items: center;
    padding-right: 20px;
    gap: 10px;
    // width: 30%;
    justify-content: end;
    @media (max-width: 720px) {
      width: 50%;
    }
    .drop-down-user-name {
      position: relative;
      cursor: pointer;
      p {
        color: white;
        display: flex;
        align-items: center;
        span {
          padding-top: 5px;
        }
      }

      .drop-down-list {
        top: 100%;
        height: 190px !important;
        transition: height 0.6s ease;
        position: absolute;
        background-color: $theme_color;
        border-radius: 4px;
        overflow: hidden;

        ul {
          margin: 0%;
          padding: 0%;
          padding-top: 10px;

          li {
            height: auto;
            color: white;
            list-style: none;
            font-weight: 500;
            white-space: nowrap;
            line-height: 24px;
            letter-spacing: -1px;
            a {
              color: white;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .drop-down-list2 {
        top: 100%;
        height: 0px;
        overflow: hidden;
        position: absolute;
        background-color: $theme_color;
        transition: height 0.6s ease;
        border-radius: 4px;

        ul {
          margin: 0%;
          padding: 0%;
          padding-top: 10px;
          // padding: 10px;
          li {
            letter-spacing: -1px;
            white-space: nowrap;
            height: auto;
            list-style: none;
            font-weight: 500;
            color: white;
            line-height: 24px;
            a {
              color: white;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    .input-div {
      background-color: white;
      display: flex;
      align-items: center;
      height: 38px;
      overflow: hidden;
      border-radius: 4px;
      input {
        border: none;
        height: 100%;
        outline: none;
        padding-left: 10px;
      }
      svg {
        font-size: 34px;
        padding-right: 10px;
      }
    }
  }
}
// }

.left-drawer {
  margin-top: 50px;
  height: calc(100vh - 12vh) !important;
  width: 300px !important;
  .ant-drawer-body {
    padding-top: 0% !important;
  }
  .ant-drawer-wrapper-body {
  }
  .ant-drawer-header {
    display: none;
  }
}
