@import '../../styles.module.scss';

.ant-dropdown-placement-bottomLeft {
  // top: 45px !important;
  // width: 200px;
  .ant-dropdown-menu {
    background-color: $theme_color;
   
    li {
      color: white !important;
      font-weight: 500 !important;
    }
  }
}
