.main_super_super_ledger {
  justify-content: space-around;
}
.super_ledger {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  font-size: 21px;
  color: #fff;
  font-weight: 600;
  border-radius: 5px 5px 0px 0px;
}
.item1 {
  background-color: rgb(222, 77, 77);
}
.item2 {
  background-color: rgb(16, 191, 53);
  @media screen and (max-width: 800px) {
    margin-top: 12px;
  }
}
.item3 {
  background-color: rgb(92, 181, 245);
  @media screen and (max-width: 800px) {
    margin-top: 12px;
  }
}
    

.withdraw_dena {
  background: #2C3E50;
  color: #fff;
  font-weight: 600;
  border: unset;
  outline: unset;
  padding: 5px 10px;
  border-radius: 4px;
}