@import "../../../styles.module.scss";
.status_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  p {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }

  .heading_icon {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: none;
    // background-color: #7e685a;
    background-color: $theme_color;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
}

.status_sub-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 10px;
  // margin-bottom: 20px;

  p {
    margin: 0;
  }

  .active {
    color: green;
  }

  .inactive {
    color: red;
  }
}

.status_slider {
  display: flex;
  // position: relative;
  // left: 40%;
  gap: 100px;
  width: calc(100% - 20px);
  padding-right: 20px;
  justify-content: end;
  align-items: center;

  // flex-direction: column;
  // justify-content: space-between;
  // border: 2px solid green;

  .status_slider-pos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border: 2px solid green;
    margin-right: 500px;
    margin-bottom: 20px;
  }

  p {
    margin: 0;
  }

  .switch_on {
    background-color: black;
  }

  .switch_off {
    background-color: white;
  }
}

.status_same {
  margin-top: 20px;
  display: flex;
  // justify-content: space-between;
  width: 85%;
  margin-bottom: 20px;
  padding-left: 60px;
  align-items: center;
  gap: 50px;
  // padding-right: 100px;

  label {
    // margin-right: 2%;
    font-size: 15px;
    width: 30%;
    font-weight: 500;
  }
  input {
    flex: 1;
  }

  .status-eye {
    position: absolute;
    right: 10%;
  }
}
.status_buttons {
  float: right;
  display: flex;
  padding-right: 25px;
  margin-bottom: 10px;
  gap: 10px;
  flex-direction: row-reverse;

  .status_back {
    display: flex;
    // gap: 5px;
    // align-items: center;

    .icons {
      font-size: 18px;
      margin: 0;
      padding: 0;
      margin-top: 5px;
      margin-right: 5px;
    }
  }
  .status_submit {
    display: flex;

    .submit_icon {
      font-size: 18px;
      margin: 0;
      padding: 0;
      margin-top: 5px;
      margin-left: 10px;
    }
  }
  button {
    background: $theme_color2;
    //    margin-right: 10px;
    //    width: 80px;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    align-items: center;

    border: none;
    color: white;
    border-radius: 5px;
  }
}
