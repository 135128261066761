.cash-tran-cont {
  .cash-tran_heading {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;
    align-items: center;
  }
  .cash_form {
    width: 100%;
    .ant-row.ant-form-item-row {
      display: block;
    }
    .ant-form-item {
      width: 100%;
    }
  }
  .range {
    width: 100%;
  }
  .submit_btn {
    background: #2c3e50;
    color: white;
    font-weight: 400;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    margin-top: 29px;
  }
  .lena_dena {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
    background: #fafafa;
    padding: 11px 0px;
    div {
      font-size: 18px;
      font-weight: 500;
    }
    
    
  }
  .deleted_btn {
    background: #2c3e50;
    color: white;
    font-weight: 400;
    border: none;
    border-radius: 5px;
    font-size: 16px;
  }
  .droup_down {
    background: #0088cc;
    color: #fff;
    padding: 5px 7px 0px;
    border-radius: 5px;
  }
}

.text_danger {
  color: red;
}
.text_success {
  color: green;
}

.ant-form-item-label{
text-align: left !important;
}