@import '../../../styles.module.scss';

.portal_cont {

    display: flex;
    flex-direction: column;
    // border-radius: 10px;
    width: 100%;
    height: 100%;
    // border: 2px solid green;

    .heading {
        display: flex;
        justify-content: space-between;
        // padding: 10px;
        align-items: center;
        padding: 10px 30px;

        h4 {
            font-size: 1.5rem;
            font-weight: 500;
            margin: 0;
            font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            letter-spacing: -0.4px;
        }
    }

    .nav {
        display: flex;
        align-items: center;
        padding: 10px 30px;
        gap: 15px;


        p {
            padding: 10px;
            
        }

        .normal {
            background: #ddd;
            border-radius: 5px;

        }

    }

    .form_cont {
        margin-top: 20px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .left {
            // border: 2px solid yellow;
            width: 27%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-right: 20px;
            padding: 10px 30px;

            .code {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .ip {
                display: flex;
                align-items: center;
                justify-content: space-between;

            }
        }

        .right {

            width: 55%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: flex-start;
            // border: 2px solid red;
            padding: 10px;

            .amount {
                // gap: 5px;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                label {
                    margin-right: 10px;
                }
            }

            .type {
                gap: 5px;
                display: flex;
                align-items: center;
                // justify-content: flex-end;
                // border: 1px solid green;

                label {
                    margin-right: 25px;
                }

                button {
                    height: 100%;
                    padding: 10px 15px;
                    background: $theme_color;
                    color: white;
                    border: none;
                    border-radius: 5px;
                    font-weight: 600;
                }


            }



        }




    }

   
    input {
        width: 200px;
        border-radius: 3px;
        border: 1px solid #666;
    }


}

.active_class {
    background-color: $theme_color;
    color: white;
    border: 1px solid black;
    border-radius: 5px;
    // width: fit-content;
    // margin-left: 20px;
}
