@import '../../../styles.module.scss';



.navigations {
    display:flex;
    justify-content: flex-end;
    float: right;
    // height: 30px;
    align-items: center;
    text-align: center;
    gap: 60px;
    margin-bottom: 10px;
    margin-top: 10px;
    // width: 100%;

    .nav {
        border-radius: 5px;
        color: white;
        height: 100%;
        font-size: 13px;
        display: flex;
        gap:15px;
        // width: 10%;
        justify-content: flex-end;
        
        
        p {
        background: $theme_color;
        display: flex;
        gap: 10px;
        padding: 10px;
        border-radius: 5px;
      
    }
}

}