.drop-header {
  width: 100%;
  height: 0px;
  // background-color: aquamarine;
  color: black;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
  text-align: center;
  overflow: hidden;
  //   background-color: rebeccapurple;
}
.drop-header-1 {
  width: 100%;
  height: 100px;
  // background-color: aquamarine;
  color: black;

  transition: height 0.5s ease-in-out;
  text-align: center;
}
.drop-icon {
  width: 100%;
  display: flex;
  padding-block: 10px;
  align-items: center;
  justify-content: center;
  svg {
    cursor: pointer;
    font-size: 20px;
    color: white;
    border-radius: 50%;
    border: 2px solid white;
    font-weight: 600;
  }
}

.drop-down-cont {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: calc(100% - 20px);
  height: 100%;
  // padding: 0px 0px 0 30px;
  color: white;
  margin-top: 20px;
  padding-inline: 20px;
  @media (max-width: 720px) {
    flex-direction: column;
    overflow-y: scroll;
    padding-bottom: 20px;
  }
  .left_details {
    width: 30%;
    height: 100%;
    @media (max-width: 720px) {
      width: 98%;
    }
    p {
      margin-top: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .middle_details {
    height: 100%;
    width: 30%;
    @media (max-width: 720px) {
      width: 98%;
    }
    // height: 100%;
    p {
      margin-top: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .right_details {
    width: 30%;
    height: 100%;
    @media (max-width: 720px) {
      width: 98%;
    }
    p {
      margin-top: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
