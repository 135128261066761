@import '../../styles.module.scss';

.commission-cont {
    width: 100%;
    margin-top: 30px;

    
    
    .commission {
        background: $theme_color2;
        font-size: 18px;
        align-items: center;
        color: white;
        font-weight: 600;
        padding: 5px 10px;
        margin-bottom: 20px;
    }
    .commission-total {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        align-items: center;
        border: 1px solid #dee2e6;
        
        .commission-breakdown {
            width: 30%;
            border-right: 1px solid #dee2e6;

           
        }
        .commission-amount {
            flex:1;
        }

    }



.upline {
    background: grey;
    padding: 10px;
    background: rgba(0,0,0,.05);
  

}

.downtime {
    background: white;
    padding: 10px;
    // background: rgba(0,0,0,.05);

}

.our {
    background: grey;
    padding: 10px;
    background: rgba(0,0,0,.05);
   

}

}