.history_inputs {
  display: flex;
  align-items: center;
  gap: 10px;
  // padding: 10px;÷
  //   height: 60px;
  // border: 2px solid green;
  // flex-direction: column;
  margin-bottom: 10px;

  width: calc(100% - 5px);
  padding-inline: 5px;
  @media (max-width: 720px) {
    flex-direction: column;
    justify-content: left;
    align-items: start;
  }

  input {
    padding: 15px;
  }
  .select-user {
    width: 200px;
    @media (max-width: 720px) {
      width: 100%;
    }
  }
  .btn-div-container {
    display: flex;
    gap: 10px;
    button {
      padding: 8px 15px;
      border: none;
      border-radius: 5px;
      background: #2c3e50;
      color: white;
    }

    .reset {
      background: rgb(239, 242, 247);
      color: black;
      border: 1px solid #d9d9d9;
    }
  }
}
