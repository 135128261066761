@import '../../styles.module.scss';

// .account-cont {
//     flex: 1;
//     .account {
//     background: $theme_color;
//     align-items: center;
//     font-size: 18px;
//     color: white;
//     font-weight: 600;
//     padding: 5px 10px;
//     margin-bottom: 30px;
//     }

//     .form  {
//         display: grid;
//         grid-template-columns: 1fr 1fr;
//         gap: 20px;
//         align-items: center;
//         font-size: 14px;

//         .first_row {
//             display: flex;
//             align-items: center;
//             gap: 20px;
//             width: 100%;

//             .type {
//                 width: 50%;
//             }

//         }

//         input {
//             width: 100%;
           
//         }
//         .account-cont_select {
//             width: 100%;
//             padding: 5px;
           
//         }
//     }

// }


.account-cont {
    flex: 1;
    .account {
    background: $theme_color2;
    align-items: center;
    font-size: 18px;
    color: white;
    font-weight: 600;
    padding: 5px 10px;
    margin-bottom: 30px;
    }

    .form  {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        gap: 10px;
        width: 100%;

        .first_row {
            display: flex;
            align-items: center;
            gap: 20px;
            width: 100%;


            .type {
                width: 50%;
            }

            .credit {
                flex: 1;

            }

        }

        input {
            // width: 100%;
           
        }

        .account-cont_select {
            width: 100%;
            padding: 5px;
           
        }
    }

}