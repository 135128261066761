@import "../../../styles.module.scss";

.account_state_form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: 720px) {
    width: calc(100% - 10px);
    padding-inline: 10px;
  }
  .form-button-div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    // border: 2px solid green;
    gap: 10px;
    flex-wrap: wrap;
    .game-name {
      display: flex;
      flex-direction: column;
      width: 250px;
      @media (max-width: 720px) {
        width: 100%;
      }
    }

    .from-date {
      display: flex;
      flex-direction: column;
      width: 200px;
      @media (max-width: 720px) {
        width: 48%;
      }
    }
    .to-date {
      display: flex;
      flex-direction: column;
      width: 200px;
      @media (max-width: 720px) {
        width: 48%;
      }
    }

    label {
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 2px;
      margin-left: 5px;
      // font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
    }
    input {
      border-radius: 5px;
    }
    .account_state-date {
      background: #e9ecef;
    }

    .statement_type {
      height: 100%;
      width: 25%;
      // border: 2px solid green;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      // align-items: center;
      @media (max-width: 720px) {
        width: 100%;
      }

      label {
        height: 100%;
      }

      select {
        padding: 7px 11px 7px;
        border-radius: 5px;
        border: 1px solid #d9d9d9;
        // height: 30px;
        width: 100%;
      }
    }
  }

  .statement_buttons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    justify-content: flex-start;
    width: 100%;

    .load {
      background: $theme_color2;
      color: white;
      font-weight: 400;
      border: none;
      padding-top: 7px;
      padding-left: 10px;
      padding-bottom: 7px;
      padding-right: 10px;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        background-color: rgb(15, 114, 163);
        transition: 0.3s;
      }
    }

    .reset {
      background: rgb(239, 242, 247);
      color: black;
      border: none;
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      padding-top: 7px;
      padding-left: 10px;
      padding-bottom: 7px;
      padding-right: 10px;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        background-color: rgb(85, 86, 88);
        color: white;
        transition: 0.3s;
      }
    }
  }
}
