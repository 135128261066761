@import url('../../../styles.module.scss');

.sports-tab {

    .ant-tabs-nav-wrap {
        background: #2C3E50;
        color: white;
        
        .ant-tabs-tab {
            padding: 12px 0px 12px 10px;
          
        }
        .ant-tabs-tab-btn {
            // background: white;
        }
        
        .ant-tabs-tab-active {
            // background: #0088CC;
            background-color: #0088cca5;
            padding: 0 20px;
            // transition: 0.2s;
            // background: red;
            // color: black;
            // margin: 10px;
            .ant-tabs-tab-btn {
                // background: black;
                color: white;
            }
        }
    }
}