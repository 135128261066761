.current_bet-cont {
  // width: 60%;
  // margin-bottom: 30px;

  .current_bet-heading {
    font-size: 20px;
    font-weight: 400;
    padding: 10px;
    margin-bottom: 20px;
    width: 100%;
  }

  .sport_type {
    display: flex;
    gap: 20px;
    cursor: pointer;
    margin-bottom: 30px;
    margin-left: 10px;
    font-size: 16px;

    .active_class {
      text-decoration: underline;
    }
  }

  .current_bet-form {
    margin-left: 15px;
    width: 100%;

    .current_bet-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border: 2px solid green;
      margin-bottom: 20px;

      .current_bet-select {
        // padding: 7px;
        margin-top: 5px;
        width: 40%;

        .sub_select {
          width: 40%;
          padding: 8px;
        }
      }

      .radio {
        display: flex;
        align-items: center;
        width: 40%;
        gap: 10px;

        input {
          width: 20px;
        }
        label {
          position: relative;
          top: 3px;
          font-size: 15px;
        }
      }

      .load-button {
        // margin-left: 30px;
        font-size: 16px;
        margin-right: 30px;
        padding-left: 10px;
        padding-top: 7px;
        padding-bottom: 7px;
        padding-right: 10px;
        // padding: 10px;
        background: #7e685a;
        border: none;
        color: white;
        border-radius: 5px;
        width: 10%;
      }
    }
  }
}
