@import '../../../styles.module.scss';

.ant-drawer-wrapper-body {

  .ant-drawer-body {
    background: rgba(0, 0, 0, 0.05);
    padding: 0px;
    padding-top: 10px;
    
  }
}


.sider-container {
  width: calc(100% - 10px);
  position: relative;
  padding-left: 4px;
  // background: rgba(0, 0, 0, 0.06);
  h2 {
    margin-top: 0px;
    margin-bottom: 0.5rem;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
    font-size: 2rem;
    padding-left: 5px;

  }

  
  .ant-menu-light.ant-menu-root.ant-menu-inline {
    border-inline-end: none;

  }
  .ant-menu-light 
  {
    background: none;

  }

.ant-menu-inline.ant-menu-root .ant-menu-item >.ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title >.ant-menu-title-content {
  flex: none;
  margin-right: 5px;
  font-size: 12px;
  color: #000;
  // font-weight:600;
  font-family: 'Roboto Condensed', sans-serif;

}
.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline{
  background: none;
}
  .sider-menu {
    .icon-vicon {
      width: 10px;
      height: 100%;
      border-radius: 0px;
      overflow: visible;
      position: relative;
      &::before {
        position: absolute;
        content: "";
        left: -5px;
        width: 8px;
        height: 8px;
        top: 50%;
        transform: translatey(-50%);
        border-radius: 100px;
        background-color: $theme_color;
      }
    }
    .icon-div {
      flex: 1;
      width: max-content;
      padding-top: 5px;
      svg {
        font-weight: 800;
      }
    }

    .ant-menu-item {
      height: 25px;
    }
    .ant-menu-light {
      border: none;
    }
    .ant-menu-item-selected {
      color: black;
      background-color: transparent !important;
    }

    .ant-menu-submenu-title {
      background-color: transparent !important;
      height: 30px !important;
      color: black;
      border-radius: 0px;
      font-weight: 500;
      position: relative;
      overflow: visible;
      margin-block: 0px;
      &:hover {
        background-color: transparent !important;
        color: black;
        text-decoration: underline;
      }
    }
    .ant-menu-submenu-arrow {
      display: none;
    }
  }
  .sider-cross-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}

