.casino_allow{
    width: 50%;
    @media screen and (max-width: 800px) {
        width: 100%;
    }
}

.casino_details {
    display: flex;
    justify-content: space-between;
    margin: 0px 20px;
    flex-wrap: wrap;
    gap: 21px;
    width: 100%;
    @media screen and (max-width: 800px) {
        margin: 0px !important;
    }
}