.history_heading {

    font-size: 20px;
    margin-bottom: 40px;
    margin-top: 10px;
}

.history_nav {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    font-size: 16px;

    .active_class {
        z-index: -19px;
        border-top: 2px solid black;
        border-left: 1px solid black;
        border-right: 1px solid black;
        width: fit-content;
        height: 30px;
        background: white;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 2px;
    }
}

.active_class {
    z-index: -99px;
    border-top: 3px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    width: 150px;
    height: 30px;
    background: green;
    text-align: center;
}

.history_inputs {
    display: flex;
    align-items: center;
    gap: 10px;
    // padding: 10px;÷
    height: 60px;

    // border: 2px solid green;

    input {
        padding: 15px;
    }

    button {
        padding: 8px 15px;
        border: none;
        border-radius: 5px;
        background: #2C3E50;
        color: white;
    }

    .reset {
        background: rgb(239, 242, 247);
        color: black;
        border: 1px solid #d9d9d9;;
    }
}