@import "../../../styles.module.scss";

.cont {
  display: flex;
  flex-direction: column;
  width: 100%;

  .profit_loss-cont {
    display: flex;
    width: 100%;
    gap: 20px;
    align-items: end;
    flex-wrap: wrap;
    @media (max-width: 720px) {
      gap: 10px;
    }
    .search {
      display: flex;
      flex-direction: column;
      width: 200px;
      @media (max-width: 720px) {
        width: 99%;
      }
    }

    .from-date {
      display: flex;
      flex-direction: column;
      width: 15%;
      @media (max-width: 720px) {
        width: 48%;
      }
    }
    .to-date {
      display: flex;
      flex-direction: column;
      width: 15%;
      @media (max-width: 720px) {
        width: 48%;
      }
    }

    button {
      // padding: .575rem 0.75rem;
      margin-top: 23px;
      color: white;
      background: $theme_color;
      border: none;
      border-radius: 4px;
      width: 10%;
    }
  }
  button {
    // padding: 10px;
    padding: 10px 15px;
    // margin-top: 20px;
    color: white;
    background: $theme_color;
    border: none;
    border-radius: 4px;
    font-size: 15px;
    // width: 10%;
  }
}
