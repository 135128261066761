.mathOdd_table {
    width: 100%;
    height: 120px;
    border: 1px solid #dee2e6;
    margin-top: 10px;

    .mathOdd_head {
height: 25px;
    

    .mathOdd_tr {
        width: 100%;
        

        .min_heading {
            width: 65%;
            display: flex;
        justify-content: flex-start;
        color: #17a2b8;
        font-size: 15px;
        padding-left: 10px;
        padding-top: 5px;
        line-height: 1;
        }
        .first {
            width: 7%;
            ;
        }
        .second {
            width: 7%;
        }
        .back-middle {
            width: 7%;
            background: #72BBEF;
        }
        .lay-middle {
            width: 7%;
            background: #FAA9BA;
        }
        .third {
            width: 7%;
        }
        .fourth {
            width: 7%;
        }
    }
}
    p {
        margin: 0;
    }
    .mathOdd_body {
        // background: rgb(191, 187, 187);
        background: rgb(230, 228, 228);
        // border: 2px solid green;

        .mathOdd_body_tr {
            border: 2px solid yellow;
        
        .name {
            padding-left: 15px;
            font-size: 14px;
        }
.first-middle {
    background: rgba(114,187,239,.5)
}
.second-middle {
    background: rgba(114,187,239,.75);
}
.span {
    display: block;
    font-size: 16px;

}
.numb-2 {
    font-size: 10px;
}
        .back-middle {
            background: #72BBEF;
            text-align: center;
        }
        .lay-middle {
            background: #FAA9BA;
            text-align: center;
        }
        .third-middle {
            
            background: rgba(250,169,186,.75);

        }

        .fourth-middle {
            background: rgba(250,169,186,.5)


        }
    }
    }
}