@import '../../../styles.module.scss';

.profit_loss-cont {
    // width: 90%;

    
    .profit_loss-heading {
        font-size: 20px;
        padding: 10px;
    }

    .profit_loss-date {
        width: 100%;
        display: flex;
        justify-content: space-between;
    // padding: 0.375rem 0.75rem;;
    align-items: center;
    // height: 100px;

    .type {
        height: 50%;
        width: 35%;

        .search {
            // width: 100%;
            min-width: 100px;
        }
    
        select {
            margin-top: 8px;
            margin-right: 10px;
            border-radius: 5px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            height: 30px;
            width: 100%;
            
        }
        .toggle-div {
            position: absolute;
            border: 1px solid black;
            border-top: none;
            width: 258px;
            border-radius: 5px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            padding: 5px;
            padding-top: 0px;
            align-items: center;

            input {
                // width: 240px;
                height: 30px;
                margin-top: 4px;
                margin-bottom: 5px;
                // border: 2px solid blue;
                border-radius: 0;
            }
            p{
                margin: 0;
                font-size: 12px;
                font-weight: 600;
            }
      
    }
    }

    .pnl-date {
        height: 50%;
        width: 25%;

        .input {
            padding:0.275rem 0.55rem;
            margin-top: 6px;
            background: #e9ecef;
            border: 1px solid #ced4da;
            border-radius: 5px;
            height: 80%;
           width: 100%;
        }
        
    }

    // .profit_loss-load {


        button {
            padding: .575rem 0.65rem;
            margin-top: 20px;
            color: white;
            background: $theme_color;
            border: none;
            border-radius: 4px;
            width: 10%;
        }
    // }
    }
}