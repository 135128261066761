.modal-cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // border: 2px solid yellow;

  .modal-heading {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    p {
      margin: 0;
      font-size: 1.2rem;
    }
  }

  .nav-bar {
    // width: 50%;
    width: 100%;

    display: flex;
    gap: 10px;

    .para {
      background: #ddd;
      color: black;
      padding: 7px 14px;
      width: fit-content;
      font-size: 14px;
    }

    .active-class {
      background: #7e685a;
      color: white;
      padding: 7px 14px;
      width: fit-content;
      font-size: 14px;
    }
  }

  .form {
    align-items: center;
    // width: 70%;
    width: 90%;
    margin-bottom: 10px;

    .code-amount {
      display: flex;
      // width: 80%;
      justify-content: space-between;
      margin-bottom: 20px;
      margin-top: 30px;

      .code {
        gap: 10px;

        label {
          font-size: 14px;
          margin-right: 50px;
        }
      }

      .amount-div {
        label {
          font-size: 14px;
          margin-right: 40px;
        }
        input {
          margin-right: 2px;
        }
      }
    }

    .IP_Amount {
      display: flex;
      justify-content: space-between;
      // width: 80%;
      margin-bottom: 20px;
      align-items: center;

      .ip-address {
        label {
          font-size: 14px;
          margin-right: 52px;
        }
      }

      .ip_amount-div {
        display: flex;

        label {
          margin-right: 43px;
          font-size: 14px;
        }

        input {
          margin-right: 88px;
        }

        button {
        }
      }
    }
  }

  .modal_table {
    .modal_head {
      tr {
        align-items: center;

        th {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .modal_body {
      background: none;
      align-items: center;
      text-align: center;
      border: 1px solid #dee2e6;
      height: 100%;
      width: 100%;

      .modal_body-tr {
        border: 1px solid #dee2e6;
      }
    }
  }
  .records-div {
    width: 97%;
    margin-bottom: 20px;
    border-top: 1px solid #dee2e6;

    .records {
      border: 1px solid #dee2e6;
      width: 99%;
      padding: 10px 0 10px 10px;
      margin: 0;
      font-size: 13px;
    }
  }
}

input {
  height: 28px;
  // width: 200px
}
