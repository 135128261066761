.table_cont {
  .current_bet_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .row-selector {
      display: flex;
      gap: 5px;
      align-items: center;
    }
    .search {
      display: flex;
      align-items: center;
      label {
        margin-right: 10px;
      }
    }
  }
}
