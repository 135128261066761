.accountlist_buttons {
    float: right;
    button {
        background: #7e685a;
       margin-right: 10px;
       width: 80px;
       height: 30px;
        border: none;
        color: white;
        border-radius: 5px;

    }
}