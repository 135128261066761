.odds_value {
    display: flex;
    width: 338px;
    @media screen and (max-width: 800px) {
        width: 100%;
    }
    .ant-form-item{
        width: 100%;
    }
}
.odds_fancy{
    .submit_btn {
        background: #2c3e50;
        color: white;
        font-weight: 400;
        border: none;
        border-radius: 5px;
        font-size: 16px;
    }
}