@import '../../../styles.module.scss';

.main {

    // border: 10px solid green;
    // border-radius: 10px;
   
    
    .heading {

        background: $theme_color;
        height: 60px;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;

        p {
            margin: 0;
            font-size: 18px;
            font-weight: 600;
            padding: 16px;
            color: white;
        }
        
    }

    .credit-deposit_nav {
        display: flex;
        justify-content: flex-start;
        gap: 20px;
        padding-top: 20px;
        margin-left: 10px;
        cursor: pointer;

        p {
            margin: 0;
        }

        .active-class {
            text-decoration: underline;
             text-decoration-color: grey;
        }

    }

    .credit-deposit_portal-form {
        padding-top: 20px;
        padding-bottom: 50px;
        padding-left: 10px;
        padding-right: 10px;
        // border: 2px solid yellow;
        align-items: center;
        background-color: #f8f8fb;
        height: 100%;
        border-radius: 10px;

        .same {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            width: 100%;
            gap: 5px;
            align-items: center;
            margin-bottom: 20px;

            .pass-eye {
                position: absolute;
                right: 5%;
            }

            .credit-deposit_portal-form_input {
                width: 100%;
                background: #EFEFEF4D;
                border: 1px solid #ced4da;
            }

            label {
                margin-right: 2%;
                font-size: 13px;
                width: 30%;
                font-weight: 500;
            }
            input {
                flex: 1;

            }
            textarea {
                flex: 1;
            }
        }
    
    .credit-deposit_buttons {
        float: right;
        // height: 35px;
        button {
            background-color: $theme_color2 !important;
        width: 80px;
        height: 100%;
        padding: 10px;
            border: none;
            color: white;
            border-radius: 5px;
            font-size: 1rem;

        }
    }

}
// }

}