

    .cont {
        width: 98vw;
        height: 100vh;
        padding: 0;

        .lucky-iframe {
            height: 100%;
            width: 100%;
            padding: 0;
            overflow-x: hidden;
        }
        .ant-layout-content {
        
            .content-comp {
                padding: 0 ;
                margin: 0px;
            }
        }
    }



    


