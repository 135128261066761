.lena_dena_modals {
  width: 90%;
  margin: auto;
  .ant-input {
    border-radius: unset !important;
    height: 34px;
  }
  .lena_dena_btn {
    background: #0088cc;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    align-items: center;
    border: none;
    color: white;
    font-weight: 900;
    border-radius: 5px;
    margin-bottom: 12px;
    &:hover{
        background: #0088cc;
    color: white !important;

    }
  }
}
