@import '../../../styles.module.scss';


.heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 20px;
    padding-bottom: 0px !important;

    p {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
    }

   .heading_icon {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: none;
    // background-color: #7e685a;
    color: white;
    font-size: 16px;
    cursor: pointer;
   }
}

.deposit_portal-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    .same {
        display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
gap: 5px;
margin-bottom: 10px;

        .deposit_portal-form_input {
            width: 100%;
            // margin-right: 10px;
        }

        label {
            // margin-right: 2%;
            font-size: 13px;
            width: 30%;
            font-weight: 500;
        }
        input {
            flex: 1;

        }
        textarea {
            flex: 1;
        }
    }
}
.status_buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    gap: 10px;
    margin-top: 25px;


    .status_back {
          display: flex;
        // gap: 5px;
        // align-items: center;

        .icons {
            font-size: 18px;
            margin: 0;
            padding: 0;
            margin-top: 5px;
            margin-right: 5px;
        }

    }
    .status_submit {
        display: flex;

        .submit_icon {
            font-size: 18px;
            margin: 0;
            padding: 0;
            margin-top: 5px;
            margin-left: 10px;

        }
    }
    button {
        background: $theme_color;
    //    margin-right: 10px;
    //    width: 80px;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    align-items: center;
      
        border: none;
        color: white;
        border-radius: 5px;

    }
}
